import { createZustandStore } from 'src/utils/zustand';
import { defaultState } from './constants';
import { NotificationStoreState } from './types';

export const { useStore: useNotificationStore, createAction } =
  createZustandStore<NotificationStoreState>('NotificationStore', defaultState, {
    persistOptions: { name: 'notifications' },
  });

/**
 * Changes the given notification option value.
 *
 */
export function setNotificationOption(
  notification: keyof NotificationStoreState,
  notify: boolean
): void {
  const setNotificationOptionAction = createAction('setNotificationOption', state => {
    state[notification] = notify;
  });

  setNotificationOptionAction();
}
