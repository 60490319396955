import { SubscriptionProductInterval } from 'src/typings/Subscription';

const ns = 'checkout';

export const checkout = {
  page: `${ns}__page`,
  spinner: `${ns}__spinner`,
  pageLoader: `${ns}__page-loader`,
  licenses: `${ns}__payment-number-of-licenses`,
  migrationLicenses: `${ns}__payment-number-of-migration-licenses`,
  convertToEducationButton: `${ns}__payment-convert-to-edu-button`,
  decreseNumberOfSubscriptionsButton: `${ns}__decrease-button`,
  devicesInput: `${ns}__devices-input`,
  subscribeButton: `${ns}__subscribe-button`,
  paymentSummarySection: `${ns}__payment-summary-section`,
  paymentSummaryNoChanges: `${ns}__payment-summary-no-changes`,
  paymentSummaryMigration: `${ns}__payment-summary-migration`,
  paymentSummaryOverview: `${ns}__payment-summary-overview`,
  paymentSummarySubtotal: `${ns}__payment-summary-subtotal`,
  paymentSummaryTax: `${ns}__payment-summary-tax`,
  paymentSummaryBalance: `${ns}__payment-summary-balance`,
  paymentSummaryBalanceMessage: `${ns}__payment-summary-balance-message`,
  paymentSummaryTotal: `${ns}__payment-summary-total`,
  paymentSummaryError: `${ns}__payment-summary-error`,
  paymentChargingDate: `${ns}__payment-charging-date`,
  checkoutPlanRadio: (interval: SubscriptionProductInterval): string =>
    `${ns}__checkout-subscription-plan-${interval}`,
  creditCardModalOpenModalAction: `${ns}__credit-card-modal-open-modal-action`,
  creditCardSummary: `${ns}__credit-card-summary`,
  discountLabel: `${ns}__discount-label`,
  billingAirtameContact: `${ns}__billing-airtame-contact`,
};
