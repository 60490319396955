import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { captureException } from '@sentry/minimal';

export function useLocalStorageState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>, () => void] {
  const [value, setValue] = useState<T>(() => {
    try {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue ? (JSON.parse(stickyValue) as T) : defaultValue;
    } catch (e) {
      captureException(e);
      return defaultValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      captureException(e);
    }
  }, [key, value]);

  const clear = () => {
    window.localStorage.removeItem(key);
  };

  return [value, setValue, clear];
}
