import { ValueOf } from 'src/typings/global';
import { Device, DeviceNetworkInterface, DevicePlatform } from './Device';
import { SubscriptionLicense, SubscriptionProductName } from './Subscription';

export type DeviceOverview = Omit<Device, 'networkState' | 'platform'> & {
  networkState: Record<string, DeviceNetworkInterface>;
  isOnline: boolean;

  // The backend response doesn't always include a platform. When it doesn't, we assume DG1.
  // This lets us process the missing platform in one place and assume that it's available
  // everywhere else.
  platform: DevicePlatform;
  status: DeviceStatus;
  groupName: string;
  groupId: number;

  network: string;
  ip: string;
  rssi: number | string;

  apText: string;
  // This may be undefined if there is no active product/trial
  activeSubscriptionProduct?: SubscriptionProductName;
  // This may be undefined if there is no active license
  activeSubscriptionLicense?: SubscriptionLicense;
  /**
   * Tells us if all devices have at least a license
   * (includes product and trial).
   */
  hasLicense: boolean;
  /**
   * Tells us if all devices have at least a license
   * (ignores trial licenses).
   */
  hasProductLicense: boolean;
};

export type DeviceOverviewById = Record<Device['id'], DeviceOverview>;

export type GroupOverview = {
  groupId: number;
  groupName: string;
  organizationId: number;
  devices: Device['id'][];
  // to distinguish between an empty group (no devices at all) and a group with no pro devices on
  // the Screens part. We filter the non `plusDevices` out, before passing the Group prop to the
  // GenericGroupView HoC.
  isEmpty?: boolean;
};

export type GroupActionDetails = Pick<GroupOverview, 'groupId' | 'groupName'>;

export const DeviceStatus = {
  offline: 'Offline',
  online: 'Online',
  idle: 'Idle',
  streaming: 'Streaming',
  updating: 'Updating',
  rebooting: 'Rebooting',
  resetting: 'Resetting',
  sleep: 'Sleep mode',
  standby: 'Standby',
} as const;

export type DeviceStatus = ValueOf<typeof DeviceStatus>;

export type MarkdownTags = {
  DEVICE_NAME: string;
  DEVICE_CHANNEL: string;
  MAIN_NETWORK_NAME: string;
  WIFI_NAME: string;
  DEVICE_IP: string;
  DEVICE_WIFI_IP: string;
  DEVICE_ETHERNET_IP: string;
  DEVICE_USB_IP: string;
  DEVICE_AP_IP: string;
  AP_PASSWORD: string;
  AP_NAME: string;
  PAIRING_CODE: string;
};

export const UpdatedDeviceStatus = {
  ...DeviceStatus,
  nostream: 'Stop stream',
} as const;

export type UpdatedDeviceStatus = ValueOf<typeof UpdatedDeviceStatus>;

export type GroupSummaryType = {
  total: number;
  online: number;
  updateAvailable: number;
  offline: number;
  standby: number;
  streaming: number;
};

export type GroupWithSummary = Pick<GroupOverview, 'groupId' | 'groupName' | 'isEmpty'> & {
  devices: DeviceOverview[];
  summary: GroupSummaryType;
};

export type DeviceGroupOverview = {
  groups: GroupOverview[];
  devicesById: DeviceOverviewById;
};
