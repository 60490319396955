import {
  ErrorCode,
  EvaluationContext,
  JsonValue,
  OpenFeatureEventEmitter,
  Provider,
  ProviderEvents,
  ProviderStatus,
  ResolutionDetails,
} from '@openfeature/web-sdk';
import { posthog } from 'posthog-js';
import { POSTHOG_API_HOST, POSTHOG_API_KEY } from 'src/constants/parameters';
import { FeatureFlag } from 'src/hooks/useFeature';

// This is a Provider for OpenFeature which uses local cookies to evaluate
// whether a feature flag is enabled or not.
//
// https://openfeature.dev/docs/reference/concepts/provider
export class PostHogFeatureProvider implements Provider {
  readonly metadata = { name: 'PostHog' } as const;
  events = new OpenFeatureEventEmitter();
  status = ProviderStatus.NOT_READY;

  private setError() {
    this.status = ProviderStatus.ERROR;
    this.events.emit(ProviderEvents.Error);
  }

  public initialize(): Promise<void> {
    posthog.init(POSTHOG_API_KEY, {
      api_host: POSTHOG_API_HOST,
      bootstrap: {
        // Since there is a delay between initializing PostHog and fetching feature flags,
        // feature flags are not always available immediately.
        // Bootstrap feature flags with default values to have them available before obtain
        // the value from PostHog
        featureFlags: Object.keys(FeatureFlag).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
      },
    });
    return new Promise(resolve => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      posthog.onFeatureFlags((flags: string[], _variants: Record<string, string | boolean>) => {
        this.status = ProviderStatus.READY;

        this.events.emit(ProviderEvents.ConfigurationChanged, { flagsChanged: flags });

        resolve();
      });
    });
  }

  public onContextChange(
    _oldContext: EvaluationContext,
    newContext: EvaluationContext
  ): Promise<void> {
    const timeout = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

    const identify = async (retries = 0) => {
      if (retries === 3) {
        this.setError();
        throw new Error('PostHogFeatureProvider: Failed to identify after three attempts');
      }
      if (this.status === ProviderStatus.READY) {
        if (newContext && newContext.targetingKey) {
          posthog.identify(newContext.targetingKey, {
            orgId: newContext.orgId,
            userEmail: newContext.email,
            userName: newContext.name,
          });
        } else {
          posthog.reset();
          // No need to error out in this case, this state can happen when a user is
          // not yet log into the Cloud.

          // TODO: consider adding a way of identifying whether a user was logged in but we failed ;
          // to obtain the userId and orgId
        }
      } else {
        await timeout(1000 * (retries + 1));
        await identify(retries + 1);
        setTimeout(
          () => {
            identify(retries + 1);
          },
          1000 * (retries + 1)
        );
      }
    };

    return identify();
  }

  public resolveBooleanEvaluation(
    flagKey: FeatureFlag,
    defaultValue: boolean = false
  ): ResolutionDetails<boolean> {
    if (this.status !== ProviderStatus.READY) {
      return { value: defaultValue, errorCode: ErrorCode.PROVIDER_NOT_READY };
    }

    return { value: Boolean(posthog.isFeatureEnabled(flagKey)) };
  }

  public resolveStringEvaluation(): ResolutionDetails<string> {
    throw new Error('Not implemented');
  }

  public resolveNumberEvaluation(): ResolutionDetails<number> {
    throw new Error('Not implemented');
  }

  public resolveObjectEvaluation<T extends JsonValue>(): ResolutionDetails<T> {
    throw new Error('Not implemented');
  }
}
