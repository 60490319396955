import React, { useEffect } from 'react';
import {
  IconAirtameLogo,
  IconDigitalSignage,
  IconGear,
  IconGift,
  IconHome,
  IconUser,
} from '@airtame/ui';
import { useHistory, useLocation } from 'react-router-dom';
import * as routes from 'src/constants/routes';
import { testIds } from 'src/constants/testids';
import useFeature, { FeatureFlag } from 'src/hooks/useFeature';
import { useShouldDisplayCalendarConnectionNotification } from 'src/hooks/useShouldDisplayCalendarConnectionNotification';
import { useGetUserData } from 'src/queries/user/useGetUserData';
import { UserPermissions } from 'src/typings/User';
import { block } from './constants';
import { SideNavIcon } from './SideNavIcon';
import { SideNavItem } from './SideNavItem';

export const SideNav = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname || routes.devices;
  const user = useGetUserData();
  const userPermission = user.permissions;
  const isSignageContentEnabled = useFeature(FeatureFlag.SIGNAGE_CONTENT);

  const showSignageItem =
    userPermission?.includes(UserPermissions.CanChangeScreenSettings) && isSignageContentEnabled;

  const showCalendarConnectionNotification = useShouldDisplayCalendarConnectionNotification();

  const organizationSubRoutes = [
    routes.alertNotifications,
    routes.exportDeviceData,
    routes.userManagement,
    routes.subscription,
    routes.billing,
    routes.organization,
    routes.calendarConnection,
  ];

  useEffect(() => {
    // Since we only trigger this when we switch pages, we guarantee that the page switch
    // takes you to the top of the page
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [currentPath]);

  const handleNavigation = (route: string) => {
    history.push(route);
  };

  const isRouteActive = (providedRoutes: string | string[]) => {
    if (Array.isArray(providedRoutes)) {
      return providedRoutes.includes(currentPath);
    }
    return providedRoutes === currentPath;
  };

  return (
    <div className={block}>
      <div className={`${block}__top`}>
        <ul className={`${block}__top-buttons`}>
          <SideNavItem className={`${block}__item--airtame-logo`}>
            <SideNavIcon Icon={IconAirtameLogo} />
          </SideNavItem>

          <SideNavItem
            isActive={isRouteActive(routes.devices)}
            data-testid={testIds.sideNav.getNavItem('devices')}
            onClick={() => handleNavigation(routes.devices)}
          >
            <SideNavIcon Icon={IconHome} />
          </SideNavItem>

          {showSignageItem && (
            <SideNavItem
              isActive={isRouteActive(routes.signage)}
              data-testid={testIds.sideNav.getNavItem('signage')}
              onClick={() => handleNavigation(routes.signage)}
            >
              <SideNavIcon Icon={IconDigitalSignage} />
            </SideNavItem>
          )}
        </ul>
      </div>
      <div className={`${block}__bottom`}>
        <ul className={`${block}__bottom-buttons`}>
          <SideNavItem data-testid={testIds.sideNav.getNavItem('update')}>
            <div id="beamerButton" className={`${block}__item-beamer-button`}>
              <SideNavIcon Icon={IconGift} />
            </div>
          </SideNavItem>

          <SideNavItem
            isActive={isRouteActive(organizationSubRoutes)}
            isAlert={showCalendarConnectionNotification}
            data-testid={testIds.sideNav.getNavItem('organizationSettings')}
            onClick={() => handleNavigation(routes.organizationSettings)}
          >
            <SideNavIcon Icon={IconGear} />
          </SideNavItem>

          <SideNavItem
            isActive={isRouteActive(routes.profile)}
            data-testid={testIds.sideNav.getNavItem('profile')}
            onClick={() => handleNavigation(routes.profile)}
          >
            <SideNavIcon Icon={IconUser} />
          </SideNavItem>
        </ul>
      </div>
    </div>
  );
};
