import { BillingCreditCardNames } from 'src/components/routes/shared/Modals/BillingModal/BillingCreditCardModal/types';

const ns = 'billing';

export const organizationBilling = {
  page: `${ns}__page`,
  historyContent: `${ns}__history-content`,
  historyTable: `${ns}__history-table`,
  historyTableDate: `${ns}__history-table-date`,
  historyTableAmount: `${ns}__history-table-amount`,
  historyTableLink: `${ns}__history-table-link`,
  billingEmail: `${ns}__billing-email`,
  editEmailButton: `${ns}__edit-email-button`,
  editAddressButton: `${ns}__edit-address-button`,
  creditCardModal: `${ns}__credit-card-modal`,
  creditCardModalConfirmAction: `${ns}__credit-card-modal-confirm`,
  creditCardModalCancelAction: `${ns}__credit-card-modal-cancel`,
  creditCardModalForm: `${ns}__credit-card-modal-form`,
  getCreditCardModalFormCardField: (fieldName: BillingCreditCardNames): string =>
    `${ns}__credit-card-modal-form-${fieldName}-field`,
  creditCardModalFormAddressFields: `${ns}__credit-card-modal-form-address-fields`,
  creditCardModalFormTaxIdFields: `${ns}__credit-card-modal-form-tax-id-fields`,
  creditCardModalFormErrors: `${ns}__credit-card-modal-form-errors`,
  billingName: `${ns}__billing-name`,
  billingCompany: `${ns}__billing-company`,
  billingAddressLine: `${ns}__billing-address-line`,
  billingAddressLine2: `${ns}__billing-address-line2`,
  billingAddressPostalCode: `${ns}__billing-address-postal-code`,
  billingAddressState: `${ns}__billing-address-state`,
  billingAddressModalFormAddressFields: `${ns}__billing-address-modal-form-address-fields`,
  billingAddressModalFormCompanyName: `${ns}__billing-address-modal-form-company-name`,
};
