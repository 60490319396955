import { useCallback, useEffect, useMemo } from 'react';
import { OpenFeature, ProviderEvents } from '@openfeature/web-sdk';
import { captureException, setTag } from '@sentry/browser';
import { ENVIRONMENT } from 'src/constants/parameters';
import { FeatureFlag } from 'src/hooks/useFeature';
import { CookieFeatureProvider } from './featureCookies';
import { PostHogFeatureProvider } from './featureProvider';

export function useOpenFeatureBootstrap(): void {
  const client = useMemo(() => OpenFeature.getClient(), []);

  const updateSentryTagFeatures = useCallback(() => {
    Object.keys(FeatureFlag).forEach(flag => {
      const value = client.getBooleanValue(flag, false);
      setTag(flag, value);
    });
  }, [client]);

  useEffect(() => {
    const onReady = () => {
      updateSentryTagFeatures();
    };

    const onConfigurationChanged = () => {
      updateSentryTagFeatures();
    };

    const onError = () => {
      captureException('Failed to start feature flag provider');
    };

    OpenFeature.addHandler(ProviderEvents.Ready, onReady);
    OpenFeature.addHandler(ProviderEvents.Error, onError);
    OpenFeature.addHandler(ProviderEvents.ConfigurationChanged, onConfigurationChanged);

    // NOTE: The posthog feature flag cannot be managed programmatically in the Acceptance_tests,
    // therefore, the ATs will use the feature Cookie provider instead.
    // The featureCookieProvider manages the featureFlag using cookies. The key of the
    // featureFlags are the same for both PostHog and featureCookie. The difference is that ATs
    // can enable a featureFlag programmatically using a cookie.
    if (ENVIRONMENT !== 'ACCEPTANCE_TESTS') {
      OpenFeature.setProvider(new PostHogFeatureProvider());
    } else {
      OpenFeature.setProvider(new CookieFeatureProvider());
    }

    return () => {
      OpenFeature.removeHandler(ProviderEvents.Ready, onReady);
      OpenFeature.removeHandler(ProviderEvents.Error, onError);
      OpenFeature.removeHandler(ProviderEvents.ConfigurationChanged, onConfigurationChanged);
    };
  }, []);
}
