import React, { Dispatch } from 'react';
import Notifications from 'react-notification-system-redux';
import { testIds } from '../constants/testids';

export const errorNotification = (
  message: string,
  autoDismissTime = 5
): ReturnType<typeof createNotification> =>
  createNotification('error', {
    message,
    autoDismiss: autoDismissTime,
    iconClass: 'ban',
    testId: testIds.notification.notificationError,
  });

export const successNotification = (message: string): ReturnType<typeof createNotification> =>
  createNotification('success', {
    message,
    iconClass: 'check',
    testId: testIds.notification.notificationSuccess,
  });

export const infoNotification = (message: string): ReturnType<typeof createNotification> =>
  createNotification('info', {
    message,
    iconClass: 'info',
    testId: testIds.notification.notificationSuccess,
  });

function createNotification(
  methodType: 'success' | 'error' | 'info',
  options: Partial<{
    message: string;
    position: string;
    autoDismiss: number;
    dismissible: boolean;
    testId: string;
    iconClass: string;
  }>
) {
  const defaultOptions = {
    message: '',
    position: 'bc',
    autoDismiss: 5,
    dismissible: false,
    testId: '',
    iconClass: '',
  };
  const { iconClass, testId, ...otherOpts } = { ...defaultOptions, ...options };

  return (dispatch: Dispatch<unknown>) =>
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      Notifications[methodType]({
        ...otherOpts,
        children: (
          <div data-testid={testId}>
            <i className={`notification__icon notification__icon--${iconClass}`} />
          </div>
        ),
      })
    );
}
