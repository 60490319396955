window.env = window.env || {};

export const {
  ENVIRONMENT,
  BACKEND_URL,
  BEAMER_KEY,
  SENTRY_DSN,
  ZENDESK_KEY,
  GOOGLE_ANALYTICS_CLIENT_KEY,
  GOOGLE_SLIDES_CLIENT_ID,
  GOOGLE_DRIVE_PICKER_API_KEY,
  GOOGLE_SSO_CLIENT_ID,
  GOOGLE_CALENDAR_CLIENT_ID,
  MICROSOFT_CALENDAR_CLIENT_ID,
  MICROSOFT_ONEDRIVE_CLIENT_ID,
  MICROSOFT_POWERPOINT_CLIENT_ID,
  MICROSOFT_POWERBI_CLIENT_ID,
  MICROSOFT_SSO_CLIENT_ID,
  DROPBOX_CLIENT_API_KEY,
  UNSPLASH_CLIENT_API_KEY,
  TRELLO_CLIENT_API_KEY,
  RISE_VISION_URL,
  AT_STRIPE_API_KEY_EU,
  AT_STRIPE_API_KEY_US,
  AT_STRIPE_PRICE_PLUS_MONTHLY_ID_EU,
  AT_STRIPE_PRICE_PLUS_YEARLY_ID_EU,
  AT_STRIPE_PRICE_PLUS_MONTHLY_ID_US,
  AT_STRIPE_PRICE_PLUS_YEARLY_ID_US,
  AT_STRIPE_PRICE_EDUCATION_MONTHLY_ID_EU,
  AT_STRIPE_PRICE_EDUCATION_YEARLY_ID_EU,
  AT_STRIPE_PRICE_EDUCATION_MONTHLY_ID_US,
  AT_STRIPE_PRICE_EDUCATION_YEARLY_ID_US,
  AT_STRIPE_PRICE_ROOMS_MONTHLY_ID_EU,
  AT_STRIPE_PRICE_ROOMS_YEARLY_ID_EU,
  AT_STRIPE_PRICE_ROOMS_MONTHLY_ID_US,
  AT_STRIPE_PRICE_ROOMS_YEARLY_ID_US,
  POSTHOG_API_KEY,
  POSTHOG_API_HOST,
} = window.env;
