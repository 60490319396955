import { organizationApi } from 'src/constants/api';
import * as http from 'src/constants/http';
import { CalendarConnections } from 'src/typings/CalendarConnection';
import { fetchJson } from 'src/utils/fetch';
import { CalendarConnectionApiModel } from './apiModels';

type GetCalendarConnectionsResponse = CalendarConnectionApiModel[];

export async function getCalendarConnections(): Promise<CalendarConnections> {
  const calendarConnections: GetCalendarConnectionsResponse = await fetchJson(
    organizationApi.calendarConnections(),
    {
      method: http.GET,
    }
  );

  return transformForFE(calendarConnections);
}

function transformForFE(calendarConnections: GetCalendarConnectionsResponse): CalendarConnections {
  return calendarConnections.reduce(
    (prev, { service, ...rest }) => {
      const { activeConnections, invalidConnections } = prev;

      if ('accessToken' in rest) {
        return {
          ...prev,
          activeConnections: { ...activeConnections, [service]: { service, ...rest } },
        };
      }

      return {
        ...prev,
        invalidConnections: { ...invalidConnections, [service]: { service, ...rest } },
      };
    },
    {
      activeConnections: {},
      invalidConnections: {},
    }
  );
}
