import { WeekDayEnum } from '../weekdays';

const ns = 'notification';

export const notification = {
  page: `${ns}__page`,
  notificationWrapper: `${ns}__wrapper`,
  notificationError: `${ns}__error`,
  notificationSuccess: `${ns}__success`,
  notificationInfoMessage: `${ns}__info-message`,
  notificationSwitch: `${ns}__activation-switch`,
  notificationEmailInput: `${ns}__email-input`,
  notificationTestEmailButton: `${ns}__test-email-button`,
  notificationDuration: `${ns}__field-durations`,
  notificationTimeFrameCheckbox: `${ns}__timeframe-checkbox`,
  notificationTimeFrameForm: `${ns}__timeframe-form`,
  notificationTimeZone: `${ns}__TimeZone-selector`,
  notificationTimeFrameFormDay: (day: WeekDayEnum): string => {
    return `${ns}__timeframe-form-${day}`;
  },
  notificationSaveButton: `${ns}__save-button`,
  notificationCancelButton: `${ns}__cancel-button`,
  notificationFormConfirmationDialog: `${ns}__confirmation-dialog`,
  notificationFormConfirmationDialogDiscardChanges: `${ns}__confirmation-dialog-discard-button`,
  notificationFormConfirmationDialogBackToEditingButton: `${ns}__confirmation-dialog-cancel-button`,
};
