import { SubscriptionProductName } from 'src/typings/Subscription';
import { createZustandStore } from 'src/utils/zustand';
import { SubscriptionStoreState } from './types';

export const { useStore: useSubscriptionStore, createAction } =
  createZustandStore<SubscriptionStoreState>('SubscriptionStore', {
    products: {
      plus: { devices: 0 },
      rooms: { devices: 0 },
      edu: { devices: 0 },
    },
    allocatedLicenses: 0,
    showAssignProLicenseModal: false,
    showAssignRoomLicenseModal: false,
    subscriptionWelcomeModal: {
      show: false,
      productName: SubscriptionProductName.plus,
      numberNewLicenses: 0,
    },
  });

export function setSubscriptionProduct({
  productName,
  productTot,
}: {
  productName: SubscriptionProductName;
  productTot: number;
}): void {
  const setProductAction = createAction('setProduct', state => {
    state.products[productName] = {
      ...state.products[productName],
      devices: productTot,
    };
  });

  setProductAction();
}

export function setSubscriptionWelcomeModal(
  subscriptionWelcomeModal: Partial<SubscriptionStoreState['subscriptionWelcomeModal']>
): void {
  const setSubscriptionWelcomeModalAction = createAction('setSubscriptionWelcomeModal', state => {
    state.subscriptionWelcomeModal = {
      ...state.subscriptionWelcomeModal,
      ...subscriptionWelcomeModal,
    };
  });

  setSubscriptionWelcomeModalAction();
}

export function toggleShowAssignLicenseModal(
  productName: SubscriptionProductName,
  visibility: boolean
): void {
  const toggleShowAssignLicenseModalAction = createAction('toggleShowAssignLicenseModal', state => {
    switch (productName) {
      case SubscriptionProductName.plus:
      case SubscriptionProductName.edu:
        state.showAssignProLicenseModal = visibility;
        break;
      case SubscriptionProductName.rooms:
        state.showAssignRoomLicenseModal = visibility;
    }
  });

  toggleShowAssignLicenseModalAction();
}

export function toggleShowAssignPlusLicenseModal(showAssignProLicenseModal: boolean): void {
  const toggleShowAssignPlusLicenseModalAction = createAction(
    'toggleShowAssignPlusLicenseModal',
    state => {
      state.showAssignProLicenseModal = showAssignProLicenseModal;
      if (!showAssignProLicenseModal) {
        state.allocatedLicenses = 0;
      }
    }
  );

  toggleShowAssignPlusLicenseModalAction();
}

export function toggleShowAssignRoomLicenseModal(showAssignRoomLicenseModal: boolean): void {
  const toggleShowAssignRoomLicenseModalAction = createAction(
    'toggleShowAssignRoomLicenseModal',
    state => {
      state.showAssignRoomLicenseModal = showAssignRoomLicenseModal;
      if (!showAssignRoomLicenseModal) {
        state.allocatedLicenses = 0;
      }
    }
  );

  toggleShowAssignRoomLicenseModalAction();
}

export function setAllocatedLicenses(allocatedLicenses: number): void {
  const setAllocatedLicensesAction = createAction('setAllocatedLicenses', {
    allocatedLicenses,
  });

  setAllocatedLicensesAction();
}
