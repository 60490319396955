import { DefinedUseQueryResult, QueryKey, useQuery } from '@tanstack/react-query';
import { listDeviceError } from 'src/constants/errorMessages';
import { getDeviceOverviewService } from 'src/services/device/getDeviceOverviewService';
import { DeviceGroupOverview } from 'src/typings/DeviceOverview';
import { UseQueryOptionsWithoutQueryKey } from 'src/typings/global';
import { APIError } from 'src/utils/error';
import { getDeviceOverviewKey } from './utils';

type useGetDeviceOverviewProps = {
  options?: UseQueryOptionsWithoutQueryKey<DeviceGroupOverview, APIError>;
  customKey?: QueryKey;
};

export function useGetDeviceOverview(
  props: useGetDeviceOverviewProps = {}
): DefinedUseQueryResult<DeviceGroupOverview, APIError> {
  const { customKey } = props;

  return useQuery({
    queryKey: customKey || getDeviceOverviewKey(),
    queryFn: () => getDeviceOverviewService(),
    ...props?.options,
    meta: {
      errorMessage: listDeviceError,
      ...props?.options?.meta,
    },
    initialData: {
      groups: [],
      devicesById: {},
    },
  });
}
