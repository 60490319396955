import { useEffect } from 'react';
import { setCloudPageVisibility } from 'src/store/applicationStore';

export function usePageVisibility(): void {
  useEffect(() => {
    const handleVisibilityChange = (): void => {
      setCloudPageVisibility(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);
}
