export const signupOrganizationCountryCode = 'signup-organization-country-code';
export const signupOrganizationName = 'signup-organization-name';
export const signupOrganizationType = 'signup-organization-type';
export const signupDisplayName = 'signup-display-name';
export const signupEmail = 'signup-email';
export const ssoIntention = 'sso-intention';
export const invitationToken = 'invitation-token';
export const pathname = 'pathname';
export const redirectAfterLogin = 'redirectAfterLogin';
export const selectedSingleGroupId = 'selectedSingleGroupId';
export const displayGuestStreamingInfoBox = 'display-guest-streaming-info-box';
