import { fetchBase, FetchBaseOptions } from './fetchBase';

export async function fetchJson<T>(
  url: string,
  options?: FetchBaseOptions,
  logoutOn401 = true
): Promise<T> {
  const response = await fetchBase(url, options, logoutOn401);
  const text = await response.text();

  // Handle empty responses (technically not valid JSON)
  if (text === '') {
    return text as T;
  }

  return JSON.parse(text) as T;
}
