import React, { useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { errorNotification } from 'src/actions/notification';
import { queryClient } from 'src/queries/queryClient';

export const QueryProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryCache = queryClient.getQueryCache();

    queryCache.config.onError = (_error, query) => {
      const errorMessage = query?.meta?.errorMessage;

      if (errorMessage && typeof errorMessage === 'string') {
        dispatch(errorNotification(errorMessage));
      }
    };
  }, []);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
