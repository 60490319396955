import { SubscriptionLicense } from 'src/typings/Subscription';

const ns = 'license';

export const license = {
  pickerCardWrapper: `${ns}__picker-card-wrapper`,
  pickerCard: (licenseName?: SubscriptionLicense): string => `${ns}__picker-card-${licenseName}`,
  pickerCardContent: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-content-${licenseName}`,
  pickerCardTitle: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-title-${licenseName}`,
  pickerCardTitleWarningIcon: (licenseName?: SubscriptionLicense): string =>
    `${ns}__card-title-warning-ico-${licenseName}`,
  pickerCardProgressBar: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-progress-bar-${licenseName}`,
  pickerCardInfo: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-info-${licenseName}`,
  pickerCardDescription: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-description-${licenseName}`,
  pickerCardActions: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-actions-${licenseName}`,
  pickerCardChangeSubscriptionAction: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-change-subscription-action-${licenseName}`,
  pickerCardRadio: (licenseName?: SubscriptionLicense): string =>
    `${ns}__picker-card-radio-${licenseName}`,
  pickerStartTrialAction: `${ns}__picker-start-trial-action`,
  pickerModal: `${ns}__picker-modal`,
  pickerModalAddLicenseAction: `${ns}__picker-modal-add-license-action`,
  pickerModalCancelAction: `${ns}__picker-modal-cancel-action`,
};
