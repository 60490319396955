import { captureException } from '@sentry/browser';
import add from 'date-fns/add';
import Cookies from 'js-cookie';
import { ErrorPayload } from 'vite';

type VitePreloadEvent = Event & {
  payload: ErrorPayload;
};

/**
 * Handle missing dynamic import, this error happens when
 * there was a new deployment and the previous scripts have been removed,
 * so the user cannot fetch the scripts since the hashes have changed.
 *
 * @see {@link https://vitejs.dev/guide/build#load-error-handling}
 */
window.addEventListener('vite:preloadError', (e: VitePreloadEvent) => {
  // The error will not be thrown if we call this method
  e.preventDefault();
  const cookieName = 'vitePreloadError';
  const vitePreloadErrorCookie = Cookies.get(cookieName);

  // To avoid an infinite loop, we first check a cookie to see if
  // we've already triggered a reload within a x time frame
  if (!vitePreloadErrorCookie) {
    Cookies.set(cookieName, '1', {
      expires: add(new Date(), { minutes: 5 }),
    });
    window.location.reload();
  } else {
    console.error(e.payload);
    captureException(e.payload);
  }
});
