import { SubscriptionProductName } from 'src/typings/Subscription';

export const root = '/';
export const devices = '/devices';
export const deviceSetup = '/devices/setup';
export const forgotPassword = '/forgot-password';
export const login = '/login';
export const profile = '/profile';
export const resetPassword = '/reset-password';
export const signup = '/signup';
export const signupCreateOrganization = '/signup/create';
export const signupCreateOrganizationSelectName = '/signup/create/organization-name';
export const signupCreateOrganizationSelectCountry = '/signup/create/organization-country';
export const signupCreateOrganizationSelectType = '/signup/create/organization-type';
export const signupCreateOrganizationSelectSignupType = '/signup/create/signup-type';
export const signupCreateOrganizationEmailSignup = '/signup/create/email-signup';
export const signupJoinOrganization = '/signup/join';
export const signupAcceptInvitation = '/signup/accept';
export const acceptTerms = '/accept-terms';
export const privacy = '/privacy';
export const authRedirect = '/auth-redirect';
export const blankRedirect = '/blank-redirect';
export const oAuth = '/auth/oauth2';
export const oAuthMicrosoft = '/auth/oauth2/microsoft';
export const oAuthGoogle = '/auth/oauth2/google';
export const oneDriveLoader = '/onedrive-redirect';
export const checkout = '/checkout';
export const checkoutWithProduct = (productName: SubscriptionProductName): string =>
  `/checkout?product=${productName}`;
export const loader = '/loader';
export const signage = '/signage';
export const organizationSettings = '/organization-settings';
export const alertNotifications = `${organizationSettings}/alert-notifications`;
export const exportDeviceData = `${organizationSettings}/export-device-data`;
export const userManagement = `${organizationSettings}/user-management`;
export const subscription = `${organizationSettings}/subscription`;
export const billing = `${organizationSettings}/billing`;
export const organization = `${organizationSettings}/organization`;
export const calendarConnection = `${organizationSettings}/calendar-connection`;
export const voucherOverview = '/voucher-overview';

// deprecated routes
export const deprecatedScreens = '/screens';
export const deprecatedHomescreen = '/homescreen';
export const deprecatedNotifications = '/notifications';
export const deprecatedUsers = '/users';
export const deprecatedAccount = '/account';
export const deprecatedLicenses = `${organizationSettings}/licenses`;

// deprecated organization creation routes
export const deprecatedSignupCreateOrganizationStep1 = '/signup/create/step1';
export const deprecatedSignupCreateOrganizationStep2 = '/signup/create/step2';
export const deprecatedSignupCreateOrganizationStep3 = '/signup/create/step3';
export const deprecatedSignupCreateOrganizationStep4 = '/signup/create/step4';
export const deprecatedSignupCreateOrganizationStep5 = '/signup/create/step5';
