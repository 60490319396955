import { createZustandStore } from 'src/utils/zustand';
import { defaultState } from './constants';
import { AuthStoreState } from './types';

export const { useStore: useAuthStore, createAction } = createZustandStore<AuthStoreState>(
  'AuthStore',
  defaultState,
  {
    persistOptions: {
      name: 'userState',
      merge: (persistedState, currentState): AuthStoreState => {
        if (!persistedState) {
          // if the persistedState is empty, we are on the legacy storage system
          // we need to migrate the old userState to Zustand
          const userStateKey = 'userState';
          const stateToMigrate = localStorage.getItem(userStateKey);
          const mergedState: AuthStoreState = stateToMigrate
            ? { ...currentState, ...JSON.parse(stateToMigrate) }
            : currentState;

          return mergedState;
        }

        return { ...currentState, ...persistedState };
      },
    },
  }
);

export function setUserIsAuthenticated(isAuthenticated: boolean): void {
  const setUserIsAuthenticatedAction = createAction('setUserIsAuthenticated', {
    isAuthenticated,
  });

  setUserIsAuthenticatedAction();
}

export function resetAuthStore(): void {
  const resetAuthStoreAction = createAction('resetAuthStore', defaultState, true);

  resetAuthStoreAction();
}
