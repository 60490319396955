import { organizationApi } from 'src/constants/api';
import { Organization } from 'src/typings/Organization';
import { countriesByCode } from 'src/utils/countries';
import { fetchJson } from 'src/utils/fetch';
import { OrganizationApiModel } from './apiModels';

type GetOrganizationApiResponse = OrganizationApiModel[];

export async function getOrganizationService(): Promise<Organization> {
  const organizations: GetOrganizationApiResponse = await fetchJson(
    organizationApi.organizationOverview()
  );

  return transformForFE(organizations);
}

export function transformForFE(organizations: GetOrganizationApiResponse): Organization {
  // The BE response is an array but in reality the BE always returns
  // one organization inside this array, so we extract it,
  // this could be improved at the API level in the future
  const organization = organizations[0];

  const { members } = organization;
  const orgOwner = members.find(m => m.isOwner) ?? null;

  let orgCountryName: string | null = null;
  if (organization.orgCountryCode) {
    const countryData = countriesByCode[organization.orgCountryCode];
    orgCountryName = countryData.label;
  }

  return {
    isEducationOrg: organization.isEducation,
    orgOwner,
    orgCountryName,
    ...organization,
  };
}
