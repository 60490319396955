import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SmartRoute } from 'src/components/routes/shared/Routes';
import { FullscreenLoader } from 'src/components/ui/FullscreenLoader';
import * as routes from 'src/constants/routes';
import { useZendesk } from 'src/hooks/useZendesk';
import { UserPermissions } from 'src/typings/User';
import { customLoadable } from 'src/utils/application';
import { MainLayout } from './MainLayout';

const fallbackOpts = { fallback: <FullscreenLoader /> };

const AcceptTerms = customLoadable(
  async () => (await import('src/components/routes/AcceptTerms')).AcceptTerms,
  fallbackOpts
);
const Devices = customLoadable(
  async () => (await import('src/components/routes/Devices')).Devices,
  fallbackOpts
);
const BlankRedirect = customLoadable(
  async () => (await import('src/components/routes/BlankRedirect')).BlankRedirect,
  fallbackOpts
);
const OneDriveRedirect = customLoadable(
  async () => (await import('src/components/routes/OneDriveRedirect')).OneDriveRedirect
);
const AuthRedirect = customLoadable(
  async () => (await import('src/components/routes/AuthRedirect')).AuthRedirect,
  fallbackOpts
);
const Signup = customLoadable(() => import('src/components/routes/Signup'), fallbackOpts);
const Login = customLoadable(
  async () => (await import('src/components/routes/Login')).Login,
  fallbackOpts
);
const Checkout = customLoadable(
  async () => (await import('src/components/routes/Checkout')).Checkout,
  fallbackOpts
);
const Profile = customLoadable(
  async () => (await import('src/components/routes/Profile')).Profile,
  fallbackOpts
);
const ForgotPassword = customLoadable(
  async () => (await import('src/components/routes/ForgotPassword')).ForgotPassword,
  fallbackOpts
);
const ResetPassword = customLoadable(
  async () => (await import('src/components/routes/ResetPassword')).ResetPassword,
  fallbackOpts
);
const PrivacyPolicy = customLoadable(
  async () => (await import('src/components/routes/PrivacyPolicy')).PrivacyPolicy,
  fallbackOpts
);
const Signage = customLoadable(
  async () => (await import('src/components/routes/Signage')).Signage,
  fallbackOpts
);
const DeviceSetup = customLoadable(
  async () => (await import('src/components/routes/Devices/DeviceSetup')).DeviceSetup,
  fallbackOpts
);
const NotFound = customLoadable(() => import('src/components/routes/NotFound'), fallbackOpts);
const OrganizationSettings = customLoadable(
  async () => (await import('src/components/routes/OrganizationSettings')).OrganizationSettings,
  fallbackOpts
);
const VoucherOverview = customLoadable(
  async () => (await import('src/components/routes/VoucherOverview')).VoucherOverview,
  fallbackOpts
);

export const Routes = (): React.ReactElement => {
  useZendesk();

  return (
    <MainLayout>
      <Switch>
        <SmartRoute exact path="/" userNeedsLogin showLogo />
        <SmartRoute exact path={routes.devices} component={Devices} showSideNav userNeedsLogin />
        <SmartRoute exact path={routes.deviceSetup} component={DeviceSetup} userNeedsLogin />
        <SmartRoute exact path={routes.profile} component={Profile} showSideNav userNeedsLogin />
        <SmartRoute exact path={routes.checkout} component={Checkout} showSideNav userNeedsLogin />
        <SmartRoute
          exact
          path={routes.signage}
          component={Signage}
          showSideNav
          userNeedsLogin
          userHasPermissions={[UserPermissions.CanChangeScreenSettings]}
        />
        <SmartRoute
          path={routes.organizationSettings}
          component={OrganizationSettings}
          userNeedsLogin
          showSideNav
        />
        <SmartRoute
          path={routes.voucherOverview}
          component={VoucherOverview}
          userNeedsLogin
          showSideNav
        />
        <SmartRoute exact path={routes.forgotPassword} component={ForgotPassword} showLogo />
        <SmartRoute exact path={routes.resetPassword} component={ResetPassword} showLogo />
        <SmartRoute exact path={routes.login} component={Login} showLogo />
        {/**
         * Under signup, the following nested routes reside as well:
         *   /signup
         *   /signup/create
         *   /signup/create/step1
         *   /signup/create/step2
         *   /signup/create/step3
         */}
        <SmartRoute path={routes.signup} component={Signup} showLogo />
        <SmartRoute exact path={routes.acceptTerms} component={AcceptTerms} showLogo />
        <SmartRoute exact path={routes.privacy} component={PrivacyPolicy} />
        <SmartRoute exact path={routes.authRedirect} component={AuthRedirect} />
        <SmartRoute exact path={routes.oneDriveLoader} component={OneDriveRedirect} />

        {/* Deprecated routes, used for redirection */}
        <SmartRoute exact path={routes.deprecatedScreens} component={FullscreenLoader} />
        <SmartRoute exact path={routes.deprecatedHomescreen} component={FullscreenLoader} />

        <Redirect exact from={routes.deprecatedNotifications} to={routes.alertNotifications} />
        <Redirect exact from={routes.deprecatedUsers} to={routes.userManagement} />
        <Redirect exact from={routes.deprecatedAccount} to={routes.subscription} />

        <Redirect
          exact
          from={routes.deprecatedSignupCreateOrganizationStep1}
          to={routes.signupCreateOrganizationSelectName}
        />
        <Redirect
          exact
          from={routes.deprecatedSignupCreateOrganizationStep2}
          to={routes.signupCreateOrganizationSelectCountry}
        />
        <Redirect
          exact
          from={routes.deprecatedSignupCreateOrganizationStep3}
          to={routes.signupCreateOrganizationSelectType}
        />
        <Redirect
          exact
          from={routes.deprecatedSignupCreateOrganizationStep4}
          to={routes.signupCreateOrganizationSelectSignupType}
        />
        <Redirect
          exact
          from={routes.deprecatedSignupCreateOrganizationStep5}
          to={routes.signupCreateOrganizationEmailSignup}
        />

        <Route exact path={routes.loader} component={FullscreenLoader} />
        {/* NOTE: The application is using the Microsoft MSAL library for SSO authentication
        with Microsoft, the library opens a pop-up in the context of Microsoft and expects
        the user to include the credentials. After submitting those credentials the page gets
        redirected back to the Cloud application within the pop-up window. At this point, the MSAL
        library gets instantiated again in the pop-up and by reading specific information included
        as url parameters is able to decide to close the pop-up and notify the host Cloud page
        that the authentication process was successful. That's why the route blank redirect is here
        it provides a page in the application without manipulating any information from the URL
        parameter and allowing the MSAL library do its own processing. */}
        <Route exact path={routes.blankRedirect} component={BlankRedirect} />
        <Route component={NotFound} />
      </Switch>
    </MainLayout>
  );
};
