const include = true;

const deviceTimezones = Object.freeze({
  'Africa/Abidjan': { include },
  'Africa/Accra': { include },
  'Africa/Addis_Ababa': { include },
  'Africa/Algiers': { include },
  'Africa/Asmara': { include },
  'Africa/Bamako': { include },
  'Africa/Bangui': { include },
  'Africa/Banjul': { include },
  'Africa/Bissau': { include },
  'Africa/Blantyre': { include },
  'Africa/Brazzaville': { include },
  'Africa/Bujumbura': { include },
  'Africa/Cairo': { include },
  'Africa/Casablanca': { include },
  'Africa/Ceuta': { include },
  'Africa/Conakry': { include },
  'Africa/Dakar': { include },
  'Africa/Dar_es_Salaam': { include },
  'Africa/Djibouti': { include },
  'Africa/Douala': { include },
  'Africa/El_Aaiun': { include },
  'Africa/Freetown': { include },
  'Africa/Gaborone': { include },
  'Africa/Harare': { include },
  'Africa/Johannesburg': { include },
  'Africa/Juba': { include },
  'Africa/Kampala': { include },
  'Africa/Khartoum': { include },
  'Africa/Kigali': { include },
  'Africa/Kinshasa': { include },
  'Africa/Lagos': { include },
  'Africa/Libreville': { include },
  'Africa/Lome': { include },
  'Africa/Luanda': { include },
  'Africa/Lubumbashi': { include },
  'Africa/Lusaka': { include },
  'Africa/Malabo': { include },
  'Africa/Maputo': { include },
  'Africa/Maseru': { include },
  'Africa/Mbabane': { include },
  'Africa/Mogadishu': { include },
  'Africa/Monrovia': { include },
  'Africa/Nairobi': { include },
  'Africa/Ndjamena': { include },
  'Africa/Niamey': { include },
  'Africa/Nouakchott': { include },
  'Africa/Ouagadougou': { include },
  'Africa/Porto-Novo': { include },
  'Africa/Sao_Tome': { include },
  'Africa/Tripoli': { include },
  'Africa/Tunis': { include },
  'Africa/Windhoek': { include },
  'America/Adak': { include },
  'America/Anchorage': { include },
  'America/Anguilla': { include },
  'America/Antigua': { include },
  'America/Araguaina': { include },
  'America/Argentina/Buenos_Aires': { include },
  'America/Argentina/Catamarca': { include },
  'America/Argentina/Cordoba': { include },
  'America/Argentina/Jujuy': { include },
  'America/Argentina/La_Rioja': { include },
  'America/Argentina/Mendoza': { include },
  'America/Argentina/Rio_Gallegos': { include },
  'America/Argentina/Salta': { include },
  'America/Argentina/San_Juan': { include },
  'America/Argentina/San_Luis': { include },
  'America/Argentina/Tucuman': { include },
  'America/Argentina/Ushuaia': { include },
  'America/Aruba': { include },
  'America/Asuncion': { include },
  'America/Atikokan': { include },
  'America/Bahia': { include },
  'America/Bahia_Banderas': { include },
  'America/Barbados': { include },
  'America/Belem': { include },
  'America/Belize': { include },
  'America/Blanc-Sablon': { include },
  'America/Boa_Vista': { include },
  'America/Bogota': { include },
  'America/Boise': { include },
  'America/Cambridge_Bay': { include },
  'America/Campo_Grande': { include },
  'America/Cancun': { include },
  'America/Caracas': { include },
  'America/Cayenne': { include },
  'America/Cayman': { include },
  'America/Chicago': { include },
  'America/Chihuahua': { include },
  'America/Costa_Rica': { include },
  'America/Creston': { include },
  'America/Cuiaba': { include },
  'America/Curacao': { include },
  'America/Danmarkshavn': { include },
  'America/Dawson': { include },
  'America/Dawson_Creek': { include },
  'America/Denver': { include },
  'America/Detroit': { include },
  'America/Dominica': { include },
  'America/Edmonton': { include },
  'America/Eirunepe': { include },
  'America/El_Salvador': { include },
  'America/Fort_Nelson': { include },
  'America/Fortaleza': { include },
  'America/Glace_Bay': { include },
  'America/Godthab': { include },
  'America/Goose_Bay': { include },
  'America/Grand_Turk': { include },
  'America/Grenada': { include },
  'America/Guadeloupe': { include },
  'America/Guatemala': { include },
  'America/Guayaquil': { include },
  'America/Guyana': { include },
  'America/Halifax': { include },
  'America/Havana': { include },
  'America/Hermosillo': { include },
  'America/Indiana/Indianapolis': { include },
  'America/Indiana/Knox': { include },
  'America/Indiana/Marengo': { include },
  'America/Indiana/Petersburg': { include },
  'America/Indiana/Tell_City': { include },
  'America/Indiana/Vevay': { include },
  'America/Indiana/Vincennes': { include },
  'America/Indiana/Winamac': { include },
  'America/Inuvik': { include },
  'America/Iqaluit': { include },
  'America/Jamaica': { include },
  'America/Juneau': { include },
  'America/Kentucky/Louisville': { include },
  'America/Kentucky/Monticello': { include },
  'America/Kralendijk': { include },
  'America/La_Paz': { include },
  'America/Lima': { include },
  'America/Los_Angeles': { include },
  'America/Lower_Princes': { include },
  'America/Maceio': { include },
  'America/Managua': { include },
  'America/Manaus': { include },
  'America/Marigot': { include },
  'America/Martinique': { include },
  'America/Matamoros': { include },
  'America/Mazatlan': { include },
  'America/Menominee': { include },
  'America/Merida': { include },
  'America/Metlakatla': { include },
  'America/Mexico_City': { include },
  'America/Miquelon': { include },
  'America/Moncton': { include },
  'America/Monterrey': { include },
  'America/Montevideo': { include },
  'America/Montserrat': { include },
  'America/Nassau': { include },
  'America/New_York': { include },
  'America/Nipigon': { include },
  'America/Nome': { include },
  'America/Noronha': { include },
  'America/North_Dakota/Beulah': { include },
  'America/North_Dakota/Center': { include },
  'America/North_Dakota/New_Salem': { include },
  'America/Ojinaga': { include },
  'America/Panama': { include },
  'America/Pangnirtung': { include },
  'America/Paramaribo': { include },
  'America/Phoenix': { include },
  'America/Port-au-Prince': { include },
  'America/Port_of_Spain': { include },
  'America/Porto_Velho': { include },
  'America/Puerto_Rico': { include },
  'America/Punta_Arenas': { include },
  'America/Rainy_River': { include },
  'America/Rankin_Inlet': { include },
  'America/Recife': { include },
  'America/Regina': { include },
  'America/Resolute': { include },
  'America/Rio_Branco': { include },
  'America/Santarem': { include },
  'America/Santiago': { include },
  'America/Santo_Domingo': { include },
  'America/Sao_Paulo': { include },
  'America/Scoresbysund': { include },
  'America/Sitka': { include },
  'America/St_Barthelemy': { include },
  'America/St_Johns': { include },
  'America/St_Kitts': { include },
  'America/St_Lucia': { include },
  'America/St_Thomas': { include },
  'America/St_Vincent': { include },
  'America/Swift_Current': { include },
  'America/Tegucigalpa': { include },
  'America/Thule': { include },
  'America/Thunder_Bay': { include },
  'America/Tijuana': { include },
  'America/Toronto': { include },
  'America/Tortola': { include },
  'America/Vancouver': { include },
  'America/Whitehorse': { include },
  'America/Winnipeg': { include },
  'America/Yakutat': { include },
  'America/Yellowknife': { include },
  'Antarctica/Casey': { include },
  'Antarctica/Davis': { include },
  'Antarctica/DumontDUrville': { include },
  'Antarctica/Macquarie': { include },
  'Antarctica/Mawson': { include },
  'Antarctica/McMurdo': { include },
  'Antarctica/Palmer': { include },
  'Antarctica/Rothera': { include },
  'Antarctica/Syowa': { include },
  'Antarctica/Troll': { include },
  'Antarctica/Vostok': { include },
  'Arctic/Longyearbyen': { include },
  'Asia/Aden': { include },
  'Asia/Almaty': { include },
  'Asia/Amman': { include },
  'Asia/Anadyr': { include },
  'Asia/Aqtau': { include },
  'Asia/Aqtobe': { include },
  'Asia/Ashgabat': { include },
  'Asia/Atyrau': { include },
  'Asia/Baghdad': { include },
  'Asia/Bahrain': { include },
  'Asia/Baku': { include },
  'Asia/Bangkok': { include },
  'Asia/Barnaul': { include },
  'Asia/Beirut': { include },
  'Asia/Bishkek': { include },
  'Asia/Brunei': { include },
  'Asia/Chita': { include },
  'Asia/Choibalsan': { include },
  'Asia/Colombo': { include },
  'Asia/Damascus': { include },
  'Asia/Dhaka': { include },
  'Asia/Dili': { include },
  'Asia/Dubai': { include },
  'Asia/Dushanbe': { include },
  'Asia/Famagusta': { include },
  'Asia/Gaza': { include },
  'Asia/Hebron': { include },
  'Asia/Ho_Chi_Minh': { include },
  'Asia/Hong_Kong': { include },
  'Asia/Hovd': { include },
  'Asia/Irkutsk': { include },
  'Asia/Jakarta': { include },
  'Asia/Jayapura': { include },
  'Asia/Jerusalem': { include },
  'Asia/Kabul': { include },
  'Asia/Kamchatka': { include },
  'Asia/Karachi': { include },
  'Asia/Kathmandu': { include },
  'Asia/Khandyga': { include },
  'Asia/Kolkata': { include },
  'Asia/Krasnoyarsk': { include },
  'Asia/Kuala_Lumpur': { include },
  'Asia/Kuching': { include },
  'Asia/Kuwait': { include },
  'Asia/Macau': { include },
  'Asia/Magadan': { include },
  'Asia/Makassar': { include },
  'Asia/Manila': { include },
  'Asia/Muscat': { include },
  'Asia/Nicosia': { include },
  'Asia/Novokuznetsk': { include },
  'Asia/Novosibirsk': { include },
  'Asia/Omsk': { include },
  'Asia/Oral': { include },
  'Asia/Phnom_Penh': { include },
  'Asia/Pontianak': { include },
  'Asia/Pyongyang': { include },
  'Asia/Qatar': { include },
  'Asia/Qyzylorda': { include },
  'Asia/Riyadh': { include },
  'Asia/Sakhalin': { include },
  'Asia/Samarkand': { include },
  'Asia/Seoul': { include },
  'Asia/Shanghai': { include },
  'Asia/Singapore': { include },
  'Asia/Srednekolymsk': { include },
  'Asia/Taipei': { include },
  'Asia/Tashkent': { include },
  'Asia/Tbilisi': { include },
  'Asia/Tehran': { include },
  'Asia/Thimphu': { include },
  'Asia/Tokyo': { include },
  'Asia/Tomsk': { include },
  'Asia/Ulaanbaatar': { include },
  'Asia/Urumqi': { include },
  'Asia/Ust-Nera': { include },
  'Asia/Vientiane': { include },
  'Asia/Vladivostok': { include },
  'Asia/Yakutsk': { include },
  'Asia/Yangon': { include },
  'Asia/Yekaterinburg': { include },
  'Asia/Yerevan': { include },
  'Atlantic/Azores': { include },
  'Atlantic/Bermuda': { include },
  'Atlantic/Canary': { include },
  'Atlantic/Cape_Verde': { include },
  'Atlantic/Faroe': { include },
  'Atlantic/Madeira': { include },
  'Atlantic/Reykjavik': { include },
  'Atlantic/South_Georgia': { include },
  'Atlantic/St_Helena': { include },
  'Atlantic/Stanley': { include },
  'Australia/Adelaide': { include },
  'Australia/Brisbane': { include },
  'Australia/Broken_Hill': { include },
  'Australia/Currie': { include },
  'Australia/Darwin': { include },
  'Australia/Eucla': { include },
  'Australia/Hobart': { include },
  'Australia/Lindeman': { include },
  'Australia/Lord_Howe': { include },
  'Australia/Melbourne': { include },
  'Australia/Perth': { include },
  'Australia/Sydney': { include },
  'Europe/Amsterdam': { include },
  'Europe/Andorra': { include },
  'Europe/Astrakhan': { include },
  'Europe/Athens': { include },
  'Europe/Belgrade': { include },
  'Europe/Berlin': { include },
  'Europe/Bratislava': { include },
  'Europe/Brussels': { include },
  'Europe/Bucharest': { include },
  'Europe/Budapest': { include },
  'Europe/Busingen': { include },
  'Europe/Chisinau': { include },
  'Europe/Copenhagen': { include },
  'Europe/Dublin': { include },
  'Europe/Gibraltar': { include },
  'Europe/Guernsey': { include },
  'Europe/Helsinki': { include },
  'Europe/Isle_of_Man': { include },
  'Europe/Istanbul': { include },
  'Europe/Jersey': { include },
  'Europe/Kaliningrad': { include },
  'Europe/Kiev': { include },
  'Europe/Kirov': { include },
  'Europe/Lisbon': { include },
  'Europe/Ljubljana': { include },
  'Europe/London': { include },
  'Europe/Luxembourg': { include },
  'Europe/Madrid': { include },
  'Europe/Malta': { include },
  'Europe/Mariehamn': { include },
  'Europe/Minsk': { include },
  'Europe/Monaco': { include },
  'Europe/Moscow': { include },
  'Europe/Oslo': { include },
  'Europe/Paris': { include },
  'Europe/Podgorica': { include },
  'Europe/Prague': { include },
  'Europe/Riga': { include },
  'Europe/Rome': { include },
  'Europe/Samara': { include },
  'Europe/San_Marino': { include },
  'Europe/Sarajevo': { include },
  'Europe/Saratov': { include },
  'Europe/Simferopol': { include },
  'Europe/Skopje': { include },
  'Europe/Sofia': { include },
  'Europe/Stockholm': { include },
  'Europe/Tallinn': { include },
  'Europe/Tirane': { include },
  'Europe/Ulyanovsk': { include },
  'Europe/Uzhgorod': { include },
  'Europe/Vaduz': { include },
  'Europe/Vatican': { include },
  'Europe/Vienna': { include },
  'Europe/Vilnius': { include },
  'Europe/Volgograd': { include },
  'Europe/Warsaw': { include },
  'Europe/Zagreb': { include },
  'Europe/Zaporozhye': { include },
  'Europe/Zurich': { include },
  'Indian/Antananarivo': { include },
  'Indian/Chagos': { include },
  'Indian/Christmas': { include },
  'Indian/Cocos': { include },
  'Indian/Comoro': { include },
  'Indian/Kerguelen': { include },
  'Indian/Mahe': { include },
  'Indian/Maldives': { include },
  'Indian/Mauritius': { include },
  'Indian/Mayotte': { include },
  'Indian/Reunion': { include },
  'Pacific/Apia': { include },
  'Pacific/Auckland': { include },
  'Pacific/Bougainville': { include },
  'Pacific/Chatham': { include },
  'Pacific/Chuuk': { include },
  'Pacific/Easter': { include },
  'Pacific/Efate': { include },
  'Pacific/Enderbury': { include },
  'Pacific/Fakaofo': { include },
  'Pacific/Fiji': { include },
  'Pacific/Funafuti': { include },
  'Pacific/Galapagos': { include },
  'Pacific/Gambier': { include },
  'Pacific/Guadalcanal': { include },
  'Pacific/Guam': { include },
  'Pacific/Honolulu': { include },
  'Pacific/Kiritimati': { include },
  'Pacific/Kosrae': { include },
  'Pacific/Kwajalein': { include },
  'Pacific/Majuro': { include },
  'Pacific/Marquesas': { include },
  'Pacific/Midway': { include },
  'Pacific/Nauru': { include },
  'Pacific/Niue': { include },
  'Pacific/Norfolk': { include },
  'Pacific/Noumea': { include },
  'Pacific/Pago_Pago': { include },
  'Pacific/Palau': { include },
  'Pacific/Pitcairn': { include },
  'Pacific/Pohnpei': { include },
  'Pacific/Port_Moresby': { include },
  'Pacific/Rarotonga': { include },
  'Pacific/Saipan': { include },
  'Pacific/Tahiti': { include },
  'Pacific/Tarawa': { include },
  'Pacific/Tongatapu': { include },
  'Pacific/Wake': { include },
  'Pacific/Wallis': { include },
  UTC: { include },
});

/**
 * @param {string} tz the timezone string
 * @return {string} the city part of the timezone
 */
function getTimezoneCity(tz) {
  const tokens = tz.split('/');
  switch (tokens.length) {
    case 1:
      // Example UTC
      return tokens[0];
    case 2:
      // Example tz Pacific/Tahiti, returns Tahiti
      return `${tokens[1]} `;
    case 3:
      // Example tz America/Kentucky/Louisville, returns Louisville
      return `${tokens[2]}`;
  }
}

/**
 * @param {string} tz the timezone string
 * @return {string} a timezone label
 */
export function formatTimezone(tz) {
  const tokens = tz.split('/').map(t => t.replace(/_/g, ' '));
  switch (tokens.length) {
    case 1:
      // Example UTC
      return tokens[0];
    case 2:
      // Example tz Pacific/Tahiti, returns Tahiti (Pacific)
      return `${tokens[1]} (${tokens[0]})`;
    case 3:
      // Example tz America/Kentucky/Louisville returns Louisville (America)
      return `${tokens[2]} (${tokens[0]})`;
  }
}

export const timezones = Object.entries(deviceTimezones)
  .filter(t => t[1].include)
  .map(t => {
    return t[0];
  })
  .sort((tz1, tz2) => {
    return getTimezoneCity(tz1).toLowerCase() < getTimezoneCity(tz2).toLocaleLowerCase() ? -1 : 1;
  });

export const timezoneOptions = timezones.map(tz => ({
  id: tz,
  label: formatTimezone(tz),
  value: tz,
}));
