export const LIST_DEVICES_REQUEST = 'LIST_DEVICES_REQUEST';
export const LIST_DEVICES_RESPONSE = 'LIST_DEVICES_RESPONSE';
export const LIST_DEVICES_ERROR = 'LIST_DEVICES_ERROR';

export const UPDATE_DEVICES_BY_ID = 'UPDATE_DEVICES_BY_ID';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';

// Subscription
export const GET_SUBSCRIPTION_STATUS_REQUEST = 'GET_SUBSCRIPTION_STATUS_REQUEST';
export const GET_SUBSCRIPTION_STATUS_RESPONSE = 'GET_SUBSCRIPTION_STATUS_RESPONSE';
export const GET_SUBSCRIPTION_STATUS_ERROR = 'GET_SUBSCRIPTION_STATUS_ERROR';

export const STOP_STREAM_REQUEST = 'STOP_STREAM_REQUEST';
export const STOP_STREAM_RESPONSE = 'STOP_STREAM_RESPONSE';

export const RESET_STORE = 'RESET_STORE';
