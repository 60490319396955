export function slugify(string: string): string {
  // Make lower case and trim
  let slug = string.toLowerCase().trim();

  // Remove accents from characters
  slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Replace invalid chars with spaces
  slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

  // Replace multiple spaces or hyphens with a single hyphen
  slug = slug.replace(/[\s-]+/g, '-');

  return slug;
}
