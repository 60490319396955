/* global Gtag */
export const EMPTY_NAME = '<empty>';

type AnalyticsProperties = Record<string, unknown>;

class GoogleAnalytics {
  // Docs for single-page application:
  // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
  page() {
    window.gtag('set', 'page_path', window.location.pathname);
    window.gtag('event', 'page_view');
  }

  identify(userId: string) {
    window.gtag('set', { user_id: userId }); // Set the user ID using signed-in user_id.
  }

  identifyOrg(orgId: number, orgName: string) {
    window.gtag('set', { orgId, orgName });
  }

  // NOTE: Anatomy of events in Gtag.js
  // Docs: https://support.google.com/analytics/answer/1033068#Anatomy&zippy=%2Cin-this-article
  // NOTE: Capturing custom parameters for Universal Analytics using custom metrics and dimensions
  // Docs: https://support.google.com/analytics/answer/2709828#zippy=%2Cin-this-article
  // Docs: https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets#send-custom-dimensions
  track(actionName: AnalyticsEvents, properties?: AnalyticsProperties) {
    const options: Gtag.EventParams = { event_category: 'cloud' };

    if (properties) {
      options.event_label = JSON.stringify(properties);
    }

    window.gtag('event', actionName, options);
  }

  createOrgIdentify(eventName: AnalyticsEvents) {
    return ({ userId, organizationId, organizationName }) => {
      this.identify(userId);

      this.identifyOrg(organizationId, organizationName);
      this.track(eventName, { orgName: organizationName, orgId: organizationId });
    };
  }
}

export enum AnalyticsEvents {
  CREATE_ORGANIZATION = 'cloud:create_organization',
  CREATE_ORGANIZATION_GOOGLE_SSO = 'cloud:create_organization_google_sso',
  CREATE_ORGANIZATION_MICROSOFT_SSO = 'cloud:create_organization_microsoft_sso',
  FORGOT_PASSWORD = 'cloud:forgot_password',
  RESET_PASSWORD = 'cloud:reset_password',
  LOGIN = 'cloud:login',
  LOGIN_MICROSOFT = 'cloud:microsoft_sso_login',
  LOGIN_GOOGLE = 'cloud:google_sso_login',
  LOGOUT = 'cloud:logout',
  ACCEPT_TERMS = 'cloud:accept_terms',
  DELETE_USER = 'cloud:delete_user',
  DELETE_DEVICE = 'cloud:delete_device',
  REBOOT_DEVICE = 'cloud:reboot_device',
  UPDATE_DEVICE = 'cloud:update_device',
  STOP_STREAM = 'cloud:stop_stream',
  STOP_STREAM_ERROR = 'cloud:stop_stream_error',
  UPDATE_DEVICE_SETTINGS_SUCCESS = 'cloud:update_device_settings_success',
  UPDATE_DEVICE_SETTINGS_ERROR = 'cloud:update_device_settings_error',
  BULK_UPDATE_DEVICE_SETTINGS_SUCCESS = 'cloud:bulk_settings_update_success',
  BULK_UPDATE_DEVICE_SETTINGS_ERROR = 'cloud:bulk_settings_update_error',
  CREATE_GROUP = 'cloud:create_group',
  EDIT_GROUP = 'cloud:edit_group',
  DELETE_GROUP = 'cloud:delete_group',
  MOVE_DEVICE = 'cloud:move_device',
  REORDER_GROUP_WITH_DND = 'cloud:reorder_group_with_dnd',
  PLAYLIST_APPS = 'cloud:playlist_apps',
  PLAYLIST_APPS_LENGTH = 'cloud:playlist_apps_length',
  SCHEDULED_APPS = 'cloud:scheduled_apps',
  SCHEDULED_APPS_LENGTH = 'cloud:scheduled_apps_length',
  ACCEPT_INVITATION = 'cloud:accept_invitation',
  ACCEPT_INVITATION_GOOGLE = 'cloud:accept_invitation_google',
  ACCEPT_INVITATION_MICROSOFT = 'cloud:accept_invitation_microsoft',
  INVITED_MEMBERS_TO_ORGANIZATION = 'cloud:invited_members_to_organization',
  CANCEL_INVITATION = 'cloud:cancel_invitation',
  REMOVE_MEMBER = 'cloud:remove_member',
  CHANGE_OWNER = 'cloud:change_owner',
  LEAVE_ORGANIZATION = 'cloud:leave_organization',
  REMOVE_ORGANIZATION = 'cloud:remove_organization',
  UPDATE_PASSWORD = 'cloud:update_password',
  UPDATE_PROFILE = 'cloud:update_profile',
  REGISTER_DEVICE = 'cloud:register_device',
  OPEN_SCREENSHOT = 'cloud:open_screenshot',
  DEVICE_MORE_INFO = 'cloud:device_more_info',
  DEVICE_MORE_INFO_ONE_CLICK = 'cloud:device_more_info_one_click',
  DEVICE_BULK_EDIT = 'cloud:device_bulk_edit',
  GET_SCREENSHOT_ERROR = 'cloud:get_screenshot_error',
  DOWNLOAD_DEVICE_LOGS = 'cloud:download_device_logs',
  EXPORT_DEVICE_CSV = 'cloud:export_devices_data',
  MASS_DEPLOYMENT_KEY_COPY = 'cloud:mass_deployment_key_copy',

  APP_CONFIGURATION_SAVED = 'app:configuration_saved',
  APP_CONFIGURATION_CANCELLED = 'app:configuration_cancelled',

  APPLY_INHERIT_SETTINGS = 'cloud:apply_inherit_settings',

  SEARCH_INPUT = 'cloud:search_input',

  START_TRIAL = 'subscription:start_trial',
  START_SUBSCRIPTION = 'subscription:start_subscription',
  CANCEL_SUBSCRIPTION = 'subscription:cancel_subscription',

  CONTENT_SCHEDULE_ONBOARDING_BLOG = 'content_schedule:onboarding_blog',
  UNIVERSAL_VIDEO_PLAYBACK_ONBOARDING_BLOG = 'universal_videoplyback:onboarding_blog',
  POWERPOINT_APP_ONBOARDING_BLOG = 'powerpoint_app:onboarding_blog',
  RISE_VISION_BLOG = 'rise_vision_app:onboarding_blog',
  // Subscription
  CONVERT_PLUS_TO_EDU = 'subscription:convert_plus_to_edu',
}
export const googleAnalytics = new GoogleAnalytics();
