import { Dispatch } from 'react';
import ExtendableError from 'es6-error';
import { errorNotification } from '../actions/notification';
import * as errorMessages from '../constants/errorMessages';
import * as localStorageKeys from '../constants/localStorageKeys';
import * as routes from '../constants/routes';
import { rootHistory } from '../utils/history';

/**
 * Custom error type so we can differentiate API errors (which we'd like to render)
 * from internal errors.
 */
export class APIError extends ExtendableError {
  public status: number;
  public data: unknown;

  constructor(status: number, message: string, additionalContent: unknown) {
    super(message);
    this.status = status;
    this.data = additionalContent;
  }
}

/**
 * Custom error type so we can differentiate Validation errors (which we'd like to render)
 * from internal errors.
 */
export class ValidationError extends ExtendableError {}

type ErrorType = Error | APIError | ValidationError;

/**
 * Provides an error message.
 */
export function getErrorMessage(err: ErrorType): string {
  if (err instanceof APIError || err instanceof ValidationError) {
    return err.message;
  }

  return errorMessages.somethingWentWrong;
}

export function handleErrorAndReturnToPreviousPath(
  dispatch: Dispatch<unknown>,
  error: unknown
): (err: ErrorType) => Promise<ErrorType> {
  // Hooks are unavailable outside the body of a function component, so we have
  // to read localstorage directly.
  let pathname = localStorage.getItem(localStorageKeys.pathname);

  if (pathname) {
    pathname = pathname.replace(/"/g, '');
  } else {
    pathname = routes.login;
  }

  rootHistory.push(pathname);

  return async (err: ErrorType) => {
    dispatch(error);
    dispatch(errorNotification(getErrorMessage(err)));

    return err;
  };
}
