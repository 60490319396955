import React from 'react';
import { useTheme } from '@airtame/ui';
import classNames from 'classnames';
import { block } from './constants';

export type LoaderProps = React.HTMLAttributes<HTMLDivElement> & {
  position?: 'default' | 'relative';
};

export const Loader = ({
  className,
  position = 'default',
  ...props
}: LoaderProps): React.ReactElement => {
  const theme = useTheme();
  const loaderClassNames = classNames(className, block, `${block}--position-${position}`);

  return <div data-theme={theme} className={loaderClassNames} {...props} />;
};
