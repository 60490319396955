import { useEffect, useState } from 'react';
import loadScript from 'load-script';
import { GOOGLE_SSO_CLIENT_ID } from 'src/constants/parameters';
import { useMountedState } from 'src/hooks/useMountedState';
import { setIdToken, setIsScriptAppended, useGoogleIdentityStore } from './googleIdentityStore';

export const useGoogleIdentityInitializer = (): void => {
  const [isApiAppended, setIsApiAppended] = useState(false);
  const [isGsiAppended, setIsGsiAppended] = useState(false);
  const isMounted = useMountedState();
  const idToken = useGoogleIdentityStore(state => state.idToken);

  useEffect(() => {
    appendGoogleScript('https://apis.google.com/js/api.js', () => setIsApiAppended(true));

    appendGoogleScript('https://accounts.google.com/gsi/client', () => {
      google.accounts.id.initialize({
        client_id: GOOGLE_SSO_CLIENT_ID,
        callback: response => {
          // This callback runs on login
          if (isMounted()) {
            setIdToken(response.credential);
          }
        },
      });
      setIsGsiAppended(true);
    });
  }, []);

  useEffect(() => {
    if (isApiAppended && isGsiAppended) {
      setIsScriptAppended();
    }
  }, [isApiAppended, isGsiAppended]);

  useEffect(() => {
    if (idToken) {
      // After the idToken is set, we immediately clear it because
      // in this way we avoid firing events multiple times
      setIdToken('');
    }
  }, [idToken]);
};

/**
 * Append the Google identity library.
 *
 * @see {@link https://developers.google.com/identity/gsi/web/guides/client-library}
 */
function appendGoogleScript(src: string, cb: () => void): void {
  // Avoid accidentally append this script twice
  if (document.querySelector(`script[src="${src}"]`)) {
    return;
  }
  const scriptOpts = { async: true, attrs: { defer: 'true' } };
  loadScript(src, scriptOpts, err => {
    if (err) {
      return;
    }

    cb();
  });
}
