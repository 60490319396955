import { createZustandStore } from 'src/utils/zustand';
import { ApplicationStoreState } from './types';

export const { useStore: useApplicationStore, createAction } =
  createZustandStore<ApplicationStoreState>('ApplicationStore', {
    isCloudPageVisible: true,
  });

export function setCloudPageVisibility(isCloudPageVisible: boolean): void {
  const setCloudPageVisibilityAction = createAction('setCloudPageVisibility', {
    isCloudPageVisible,
  });

  setCloudPageVisibilityAction();
}
