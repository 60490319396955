import { createZustandStore } from 'src/utils/zustand';
import { ModalStoreState } from './types';

export const { useStore: useModalStore, createAction } = createZustandStore<ModalStoreState>(
  'ModalStore',
  {
    isTrialWelcomeModalVisible: false,
    isCancelSubscriptionModalVisible: false,
    isBillingCreditCardModalVisible: false,
    isBillingAddressModalVisible: false,
    addLicenseModalDeviceId: undefined,
  }
);

export function setIsTrialWelcomeModalVisible(isTrialWelcomeModalVisible: boolean): void {
  const setIsTrialWelcomeModalVisibleAction = createAction('setIsTrialWelcomeModalVisible', {
    isTrialWelcomeModalVisible,
  });

  setIsTrialWelcomeModalVisibleAction();
}

export function setIsBillingAddressModalVisible(isBillingAddressModalVisible: boolean): void {
  const setIsBillingAddressModalVisibleAction = createAction('setIsBillingAddressModalVisible', {
    isBillingAddressModalVisible,
  });

  setIsBillingAddressModalVisibleAction();
}

export function setIsBillingCreditCardModalVisible(isBillingCreditCardModalVisible: boolean): void {
  const setIsBillingCreditCardModalVisibleAction = createAction(
    'setIsBillingCreditCardModalVisible',
    {
      isBillingCreditCardModalVisible,
    }
  );

  setIsBillingCreditCardModalVisibleAction();
}

export function setIsCancelSubscriptionModalVisible(
  isCancelSubscriptionModalVisible: boolean
): void {
  const setIsCancelSubscriptionModalVisibleAction = createAction(
    'setIsCancelSubscriptionModalVisible',
    {
      isCancelSubscriptionModalVisible,
    }
  );

  setIsCancelSubscriptionModalVisibleAction();
}

export function setAddLicenseModalDeviceId(addLicenseModalDeviceId?: number): void {
  const setAddLicenseModalDeviceIdAction = createAction('setAddLicenseModalDeviceId', {
    addLicenseModalDeviceId,
  });

  setAddLicenseModalDeviceIdAction();
}
