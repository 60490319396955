import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getDeviceOverviewKey } from 'src/queries/device/utils';
import { getOrganizationKey } from 'src/queries/organization/utils';
import { queryClient } from 'src/queries/queryClient';
import { RootState } from 'src/reducers';
import { getSubscriptionService } from 'src/services/subscriptions/getSubscriptionService';
import { getTrialService } from 'src/services/subscriptions/getTrialService';
import { transformSubscriptionResponse } from 'src/services/subscriptions/subscriptionTransforms';
import * as actions from '../constants/actions';
import { getSubscriptionStatusErrorMessage } from '../constants/errorMessages';
import { SubscriptionTransformResponse } from '../typings/Subscription';

function getSubscriptionStatusRequest() {
  return { type: actions.GET_SUBSCRIPTION_STATUS_REQUEST };
}

function getSubscriptionStatusResponse(subscription: SubscriptionTransformResponse): {
  type: string;
  payload: SubscriptionTransformResponse;
} {
  return {
    type: actions.GET_SUBSCRIPTION_STATUS_RESPONSE,
    payload: subscription,
  };
}

function getSubscriptionStatusError() {
  return { type: actions.GET_SUBSCRIPTION_STATUS_ERROR };
}

export function getSubscriptionStatus(): ThunkAction<void, RootState, {}, AnyAction> {
  return async dispatch => {
    dispatch(getSubscriptionStatusRequest());

    try {
      const subscriptionInfo = await getSubscriptionInfo();
      await queryClient.invalidateQueries({ queryKey: getOrganizationKey() });
      await queryClient.invalidateQueries({ queryKey: getDeviceOverviewKey() });
      dispatch(getSubscriptionStatusResponse(subscriptionInfo));
    } catch (error) {
      dispatch(getSubscriptionStatusError());
    }
  };
}

const getSubscriptionInfo = async () => {
  try {
    const [subscription, trial] = await Promise.all([getSubscriptionService(), getTrialService()]);
    return transformSubscriptionResponse(subscription, trial);
  } catch {
    return Promise.reject(getSubscriptionStatusErrorMessage);
  }
};
