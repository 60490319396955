import { useGetCalendarConnections } from 'src/queries/organization/useGetCalendarConnections';
import { useGetUserData } from 'src/queries/user/useGetUserData';
import { useAuthStore } from 'src/store/authStore';
import { useNotificationStore } from 'src/store/notificationStore';
import { UserRole } from 'src/typings/User';

/**
 * Returns true if the call to action notification for the Calendar Connections should be visible
 * throughout the Application.
 * It's true if the user is an admin or owner, and doesn't have any calendar connections set up, and
 * hasn't dismissed the notification yet.
 *
 */
export function useShouldDisplayCalendarConnectionNotification(): boolean {
  const user = useGetUserData();
  const userRole = user.role;
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const isAdminOrOwner = userRole === UserRole.administrator || userRole === UserRole.owner;

  const { data } = useGetCalendarConnections({
    options: {
      enabled: isAuthenticated,
    },
  });
  const { activeConnections, invalidConnections } = data;
  const hasCalendarConnections =
    !!Object.keys(activeConnections).length || !!Object.keys(invalidConnections).length;
  const isAddCalendarConnectionNotificationVisible = useNotificationStore(
    state => state.addCalendarConnection
  );

  return isAdminOrOwner && !hasCalendarConnections && isAddCalendarConnectionNotificationVisible;
}
