import * as actions from 'src/constants/actions';
import {
  CreateSubscriptionModalPayload,
  FreeSubscriptionStatus,
  PristineSubscriptionStatus,
  SubscriptionInfo,
  SubscriptionStatus,
  SubscriptionStatusType,
  SubscriptionTransformResponse,
} from 'src/typings/Subscription';

export type SubscriptionState = {
  isInitialized: boolean;
  isSubscriptionFetched: boolean;
  subscriptionInfo: SubscriptionInfo | null;
  plusProduct: SubscriptionStatus;
  roomsProduct: SubscriptionStatus;
  educationProduct: SubscriptionStatus;
  isFetchingStatus: boolean;
};

export const pristineSubscription: PristineSubscriptionStatus = {
  statusType: SubscriptionStatusType.PRISTINE,
};

export const defaultFreeSubscription: FreeSubscriptionStatus = {
  statusType: SubscriptionStatusType.FREE,
  wasTrial: false,
  expirationDate: '1970-01-01', // see note below
};

const initialState: SubscriptionState = {
  isInitialized: false,
  isSubscriptionFetched: false,
  subscriptionInfo: null,
  plusProduct: pristineSubscription,
  roomsProduct: pristineSubscription,
  educationProduct: pristineSubscription,
  isFetchingStatus: false,
};

export default function subscription(
  state = initialState,
  action: {
    type: string;
    payload: SubscriptionTransformResponse | number | string | CreateSubscriptionModalPayload;
  }
): SubscriptionState {
  switch (action.type) {
    case actions.GET_SUBSCRIPTION_STATUS_RESPONSE: {
      const payload = action.payload as SubscriptionTransformResponse;
      return {
        ...state,
        isFetchingStatus: false,
        isInitialized: true,
        isSubscriptionFetched: true,
        plusProduct: payload.plus,
        educationProduct: payload.education,
        roomsProduct: payload.rooms,
        subscriptionInfo: payload.subscriptionInfo,
      };
    }
    case actions.GET_SUBSCRIPTION_STATUS_REQUEST:
      return {
        ...state,
        isFetchingStatus: true,
      };
    case actions.GET_SUBSCRIPTION_STATUS_ERROR:
      return {
        ...state,
        isSubscriptionFetched: true,
        isFetchingStatus: false,
      };
    default:
      return {
        ...state,
      };
  }
}
