import { DefinedUseQueryResult, QueryKey, useQuery } from '@tanstack/react-query';
import { getCalendarConnections } from 'src/services/organization/getCalendarConnections';
import { CalendarConnections } from 'src/typings/CalendarConnection';
import { UseQueryOptionsWithoutQueryKey } from 'src/typings/global';
import { APIError } from 'src/utils/error';
import { getCalendarConnectionsKey } from './utils';

type UseGetCalendarConnectionsProps = {
  options?: UseQueryOptionsWithoutQueryKey<CalendarConnections, APIError>;
  customKey?: QueryKey;
};

export function useGetCalendarConnections(
  { options, customKey }: UseGetCalendarConnectionsProps = { options: {} }
): DefinedUseQueryResult<CalendarConnections, APIError> {
  return useQuery({
    queryKey: customKey || getCalendarConnectionsKey(),
    queryFn: () => getCalendarConnections(),
    ...options,
    initialData: {
      activeConnections: {},
      invalidConnections: {},
    },
  });
}
