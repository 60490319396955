import differenceInDays from 'date-fns/differenceInDays';
import { defaultFreeSubscription, pristineSubscription } from 'src/reducers/subscription';
import { SubscriptionApiModel } from 'src/services/subscriptions/apiModels';
import { currencyToSymbol } from 'src/services/subscriptions/utils';
import {
  PaidSubscriptionStatus,
  SubscriptionCurrentStatus,
  SubscriptionInfo,
  SubscriptionProductFullName,
  SubscriptionProductName,
  SubscriptionProductShortName,
  SubscriptionStatus,
  SubscriptionStatusType,
  SubscriptionTransformResponse,
  Trial,
} from 'src/typings/Subscription';

type SubscriptionProductTemp = PaidSubscriptionStatus | undefined;
type TrialProductTemp = Trial | undefined;

/**
 * Transform response so that it returns the most advantageous
 * subscription to the user.
 */
export function transformSubscriptionResponse(
  subscriptionResp: SubscriptionApiModel | null,
  trialResp: Trial | null
): SubscriptionTransformResponse {
  return {
    plus: transformSubscriptionProductByName(
      SubscriptionProductName.plus,
      SubscriptionProductFullName.plus,
      SubscriptionProductShortName.plus,
      subscriptionResp,
      trialResp
    ),
    rooms: transformSubscriptionProductByName(
      SubscriptionProductName.rooms,
      SubscriptionProductFullName.rooms,
      SubscriptionProductShortName.rooms,
      subscriptionResp,
      trialResp
    ),
    education: transformSubscriptionProductByName(
      SubscriptionProductName.edu,
      SubscriptionProductFullName.edu,
      SubscriptionProductShortName.edu,
      subscriptionResp,
      trialResp
    ),
    subscriptionInfo: transformSubscriptionInfo(subscriptionResp),
  };
}

/**
 * Transform subscription info to match SubscriptionInfo shape.
 */
function transformSubscriptionInfo(
  subscriptionResponse: SubscriptionApiModel | null
): SubscriptionInfo | null {
  if (!subscriptionResponse) {
    return null;
  }

  const { type: paymentType, ...rest } = subscriptionResponse;

  return { paymentType, ...rest };
}

/**
 * Transform the subscription product based on 'SubscriptionProductName' type.
 */
function transformSubscriptionProductByName(
  productName: SubscriptionProductName,
  productFullName: SubscriptionProductFullName,
  productShortName: SubscriptionProductShortName,
  subscriptionResp: SubscriptionApiModel | null,
  trialsResp: Trial | null
): SubscriptionStatus {
  const subscription = getSubscriptionProductFromResp();

  return getMostAdvantageousSubscriptionProduct(subscription, trialsResp ?? undefined, productName);

  function getSubscriptionProductFromResp(): SubscriptionProductTemp {
    const product = subscriptionResp?.products.find(product => product.name === productName);
    if (!subscriptionResp || !product) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { products, type: paymentType, ...rest } = subscriptionResp;
    return {
      ...rest,
      ...product,
      // Stripe represents amount with 2 decimals,
      // for example, 20$ gets represented as 2000 unitAmount
      unitAmount: product.unitAmount / 100,
      paymentType,
      fullName: productFullName,
      shortName: productShortName,
      // This is a temp statusType, we compute the actual status
      // in the getMostAdvantageousSubscriptionProductType function
      statusType: SubscriptionStatusType.PAID,
      currencySymbol: currencyToSymbol(rest.currency),
    };
  }
}

/**
 * Get the data for the most advantageous product.
 */
function getMostAdvantageousSubscriptionProduct(
  subscription: SubscriptionProductTemp,
  trial: TrialProductTemp,
  productName: SubscriptionProductName
): SubscriptionStatus {
  const freeSubscription = {
    ...defaultFreeSubscription,
  };

  switch (getMostAdvantageousSubscriptionProductType(subscription, trial)) {
    case SubscriptionStatusType.PRISTINE: {
      return pristineSubscription;
    }
    case SubscriptionStatusType.FREE: {
      return {
        ...freeSubscription,
        expirationDate:
          (checkIsTrialInactive(trial) ? trial?.stop : subscription?.expirationDate) ||
          freeSubscription.expirationDate,
        wasTrial: checkIsTrialInactive(trial),
      };
    }
    case SubscriptionStatusType.TRIAL: {
      if (!trial) {
        return freeSubscription;
      }
      return {
        statusType: SubscriptionStatusType.TRIAL,
        name: productName,
        startDate: trial.start,
        expirationDate: trial.stop,
        trialDaysLeft: differenceInDays(new Date(trial.stop || Date.now()), new Date()),
      };
    }
    case SubscriptionStatusType.PAID: {
      return subscription ?? freeSubscription;
    }
  }
}

/**
 * Get the most advantageous subscriptions type, check the explanatory diagram on:
 * https://gitlab.com/airtame/cloud/frontend/-/blob/master/docs/subscriptions-product-logic.png
 */
function getMostAdvantageousSubscriptionProductType(
  subscription: SubscriptionProductTemp,
  trial: TrialProductTemp
): SubscriptionStatusType {
  if (checkIsPristine(subscription, trial)) {
    return SubscriptionStatusType.PRISTINE;
  } else if (checkIsPaid(subscription)) {
    return SubscriptionStatusType.PAID;
  } else if (checkIsTrial(trial)) {
    return SubscriptionStatusType.TRIAL;
  } else if (checkIsFree(subscription, trial)) {
    return SubscriptionStatusType.FREE;
  } else {
    // If we cannot detect the subscription for whatever reason
    // we return the FREE type
    return SubscriptionStatusType.FREE;
  }
}

function checkIsPristine(subscription: SubscriptionProductTemp, trial: TrialProductTemp): boolean {
  return !subscription && !trial;
}

function checkIsFree(subscription: SubscriptionProductTemp, trial: TrialProductTemp): boolean {
  if (!subscription) {
    return checkIsTrialInactive(trial);
  } else {
    return checkIsPaidInactive(subscription) && (!trial || checkIsTrialInactive(trial));
  }
}

function checkIsPaid(subscription: SubscriptionProductTemp): boolean {
  return (
    subscription?.status === SubscriptionCurrentStatus.Active ||
    subscription?.status === SubscriptionCurrentStatus.PastDue
  );
}

function checkIsPaidInactive(subscription: SubscriptionProductTemp): boolean {
  return subscription?.status === SubscriptionCurrentStatus.Inactive;
}

function checkIsTrial(trial: TrialProductTemp) {
  return !!trial?.isActive;
}

function checkIsTrialInactive(trial: TrialProductTemp): boolean {
  return !!(trial && !trial?.isActive);
}
