const ns = 'device';

export const deviceSettingsKeys = {
  deviceOverview: `${ns}-overview`,
  deviceSettings: `${ns}-settings`,
  devicePeripherals: `${ns}-peripherals`,
  getDeviceActivationStatus: `${ns}-activation-status`,
  deviceSettingsForSignage: `${ns}-settings-signage`,
  deviceLatestFirmwareVersions: `${ns}-latest-firmware-versions`,
};
