const ns = 'organization';

export const organization = {
  page: `${ns}__page`,
  organizationTooltip: `${ns}__organization-tooltip`,
  organizationTooltipTarget: `${ns}__organization-tooltip-target`,
  organizationName: `${ns}__organization-name`,
  editOrganizationNameInput: `${ns}__organization-edit-name-input`,
  editOrganizationNameButton: `${ns}__organization-edit-name-button`,
  removeOrganizationButton: `${ns}__organization-remove-organization-button`,
  confirmRemoveOrganizationInput: `${ns}__organization-confirm-remove-organization-input`,
  submitRemoveOrganization: `${ns}__organization-submit-remove-organization`,
};
