import { CalendarConnections } from 'src/typings/CalendarConnection';
import { organizationKeys } from './constants';

export function getOrganizationKey(): string[] {
  return [organizationKeys.overview];
}

export function getOrganizationSettingsKey(): string[] {
  return [organizationKeys.settings];
}

export function getCalendarConnectionsKey(): string[] {
  return [organizationKeys.calendarConnections];
}

export function getOrganizationAccessibleGroupsKey(): string[] {
  return [organizationKeys.accessibleGroups];
}

export function getBillingEmailKey(): string[] {
  return [organizationKeys.billingEmail];
}

export function getBillingAddressKey(): string[] {
  return [organizationKeys.billingAddress];
}

export function getNextInvoiceKey(): string[] {
  return [organizationKeys.nextInvoice];
}

export function removeCalendarConnectionById(
  calendarConnections: CalendarConnections | undefined,
  id: number
): CalendarConnections | undefined {
  if (!calendarConnections?.activeConnections) {
    return;
  }

  return {
    ...calendarConnections,
    activeConnections: Object.values(calendarConnections.activeConnections).reduce(
      (connections, connection) => {
        if (connection.id !== id) {
          connections[connection.service] = connection;
        }

        return connections;
      },
      {}
    ),
  };
}
