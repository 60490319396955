const ns = 'organization';

export const organizationKeys = {
  overview: `${ns}-overview`,
  accessibleGroups: `${ns}-accessible-groups`,
  settings: `${ns}-settings`,
  calendarConnections: `${ns}-calendar-connections`,
  billingEmail: `${ns}-billing-email`,
  billingAddress: `${ns}-billing-address`,
  nextInvoice: `${ns}-next-invoice`,
};
