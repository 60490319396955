/**
 * Handles store configuration
 */
import { createReduxEnhancer } from '@sentry/react';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import resetEnhancer, { appReducer } from '../reducers';

const sentryReduxEnhancer = createReduxEnhancer({});

// Disable devTools and redux-logger, except in development
const middlewares = [thunk];
const composeEnhancers =
  // eslint-disable-next-line dot-notation
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function configureStore() {
  const store = createStore(
    resetEnhancer(appReducer()),
    composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
  );

  return store;
}

export const store = configureStore();
