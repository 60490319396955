import React from 'react';
import { Theme } from '@airtame/ui';
import { withProfiler } from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { useGoogleIdentityInitializer } from 'src/components/routes/shared/GoogleAuth/useGoogleIdentityInitializer';
import { MicrosoftAuthContextProvider } from 'src/components/routes/shared/MicrosoftAuth';
import { store } from 'src/store/store';
import { rootHistory } from 'src/utils/history';
import { BootstrapData } from './BootstrapData';
import { QueryProvider } from './QueryProvider';
import { Routes } from './Routes';
import { useOpenFeatureBootstrap } from './useOpenFeatureBootstrap';
import { usePageVisibility } from './usePageVisibility';

/**
 * Root component. Takes care of initializing all routing and history for the application
 * @return {JSX} The application's data
 */
function RootComponent() {
  useGoogleIdentityInitializer();
  usePageVisibility();
  useOpenFeatureBootstrap();

  return (
    <Provider store={store}>
      <QueryProvider>
        <MicrosoftAuthContextProvider>
          <Theme theme="dark">
            <BootstrapData>
              <Router history={rootHistory}>
                <Routes />
              </Router>
            </BootstrapData>
          </Theme>
        </MicrosoftAuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
      </QueryProvider>
    </Provider>
  );
}

export const Root = withProfiler(RootComponent);
