import { CalendarProvider } from 'src/typings/CalendarConnection';

const ns = 'calendar-connection';

export const organizationCalendarConnection = {
  page: `${ns}__page`,
  signInButton: `${ns}__sign-in-button`,
  disconnectButton: `${ns}__disconnect`,
  connectedIcon: `${ns}__connected`,
  confirmDisconnectDialog: `${ns}__confirm-disconnect-dialog`,
  confirmDisconnectButton: `${ns}__confirm-disconnect-button`,
  connectionNotification: `${ns}__connection-notification`,
  connectionCard: (provider: CalendarProvider): string => `${ns}__${provider}__connection-card`,
  connectionExpired: (provider: CalendarProvider): string =>
    `${ns}__${provider}__connection-expired`,
};
