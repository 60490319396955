import React from 'react';
import loadable, {
  DefaultComponent,
  LoadableComponent,
  OptionsWithoutResolver,
} from '@loadable/component';

export const customLoadable = <T extends {}>(
  loadFn: (props: T) => Promise<DefaultComponent<T>>,
  options?: OptionsWithoutResolver<T>
): LoadableComponent<T> => loadable(loadFn, { fallback: <></>, ...options });
