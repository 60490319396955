const ns = 'profile';

export const profile = {
  page: `${ns}__page`,
  editNameButton: `${ns}__edit-name-button`,
  changePasswordButton: `${ns}__change-password-button`,
  confirmButton: `${ns}__confirm-button`,
  logoutButton: `${ns}__logout-button`,
  currentPassword: `${ns}__current-password`,
  newPassword: `${ns}__new-password`,
  repeatedPassword: `${ns}__repeated-password`,
  currentName: `${ns}__user-name`,
  newName: `${ns}__new-name`,
  removeAccount: `${ns}__remove-account-button`,
  removeAccountConfirmationInput: `${ns}__remove-account-confirmation-input`,
  submitRemoveAccount: `${ns}__submit-remove-account`,
};
