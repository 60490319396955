const ns = 'signup';

export const signup = {
  page: `${ns}__page`,
  invitationPage: `${ns}__invitation-page`,
  createOrgButton: `${ns}__create-organization-button`,
  createOrgChooseNameButton: `${ns}__create-organization-choose-name-button`,
  createOrgChooseNameInput: `${ns}__create-organization-choose-name-input`,
  createOrgCountrySelect: `${ns}__create-organization-country-select`,
  createOrgCountryButton: `${ns}__create-organization-country-button`,
  createOrgTypeSelect: `${ns}__create-organization-type-select`,
  createOrgTypeButton: `${ns}__create-organization-type-button`,
  createOrgSignupWithEmailButton: `${ns}__create-organization-email-button`,
  createOrgSignupWithEmailCompleteButton: `${ns}__create-organization-email-complete-button`,
};
