import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { setShowLogo, setShowSideNav } from 'src/components/routes/root/MainLayout/mainLayoutStore';
import { FullscreenLoader } from 'src/components/ui/FullscreenLoader';
import { redirectAfterLogin as redirectAfterLoginLocalStorageKey } from 'src/constants/localStorageKeys';
import * as routes from 'src/constants/routes';
import { useHasUserPermission } from 'src/hooks/useHasUserPermission';
import { useLocalStorageState } from 'src/hooks/useLocalStorageState';
import { useGetUserData } from 'src/queries/user/useGetUserData';
import { useAuthStore } from 'src/store/authStore';
import { UserPermission, UserPermissions } from 'src/typings/User';

type SmartRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
  userNeedsLogin?: boolean;
  userHasPermissions?: UserPermission[];
  showSideNav?: boolean;
  showLogo?: boolean;
} & RouteProps;

export const SmartRoute: React.FC<SmartRouteProps> = ({
  component: Component = () => <></>,
  userNeedsLogin = false,
  userHasPermissions,
  showSideNav,
  showLogo,
  ...rest
}): JSX.Element => {
  const userIsAuthenticated = useAuthStore(state => state.isAuthenticated);
  const user = useGetUserData();

  const userIsInitialized = !!user?.id;
  const userPermissions = user.permissions;
  const termsAccepted = user.termsAccepted;

  const canBuySubscription = useHasUserPermission(UserPermissions.FeCanBuySubscriptions);
  // Unfortunately, the way we handle authentication, redirection and logout, there is some race
  // condition about moving the user from the current page before logout to /login and resetting
  // the redux state we just want to make sure we don't store the redirectRoute on logout as well
  // e.g. User is on /screens => they logs out => the condition from useEffect is met
  // (!userIsAuthenticated && userNeedsLogin) => a new redirectAfterLogin is set up with "/screens"
  // If we first move the user to /login and then we logout the user: they is redirected to /login,
  // but it is still logged in => redirected to /devices => they is logged out => (1) scenario
  const [onBootstrap, setOnBootstrap] = useState(true);

  const [redirectAfterLogin, setRedirectAfterLogin, clearRedirectAfterLogin] = useLocalStorageState(
    redirectAfterLoginLocalStorageKey,
    routes.root
  );

  // Using the path prop for redirection only works with top level routes
  // To be able to redirect to nested routes, we need to store the whole original location
  const location = useLocation();
  const originalPath = useRef(location.pathname);
  const path = location.pathname;

  useEffect(() => {
    if (!userIsAuthenticated && userNeedsLogin && onBootstrap) {
      setRedirectAfterLogin(originalPath.current);
      setOnBootstrap(false);
    }
    if (userIsAuthenticated) {
      setRedirectAfterLogin('');
      clearRedirectAfterLogin();
    }
  }, [userIsAuthenticated, userNeedsLogin, onBootstrap]);

  useEffect(() => {
    setShowSideNav(!!showSideNav);
  }, [showSideNav]);

  useEffect(() => {
    setShowLogo(!!showLogo);
  }, [showLogo]);

  // display the loader component if user lands on some pages that need login (because they use
  // permissions) or if they land on "/" wait for getting user profile and then redirect
  if (userIsAuthenticated && !userIsInitialized) {
    return <FullscreenLoader />;
  }

  // Handle default path redirection since ATM user roles would allow an user to either see at
  // least one of the Devices or Screens tab
  // Legacy: We are removing the `screens` (or `homescreen`) route, and make sure to redirect to
  // `/devices` instead.
  if ([routes.root, routes.deprecatedScreens, routes.deprecatedHomescreen].includes(path)) {
    return <Redirect to={routes.devices} />;
  }

  // Handle unauthenticated request
  if (!userIsAuthenticated && userNeedsLogin) {
    return <Redirect to={routes.login} />;
  }

  // Handle terms not accepted
  if (userIsAuthenticated && userNeedsLogin && !termsAccepted) {
    return <Redirect to={routes.acceptTerms} />;
  }

  if (
    userIsAuthenticated &&
    userNeedsLogin &&
    userHasPermissions?.every(permission => userPermissions?.includes(permission)) === false
  ) {
    return <Redirect to="/" />;
  }

  // after final steps of login / signup / accept invitation handle redirection
  if (
    userIsAuthenticated &&
    [
      routes.login,
      // SSO redirect routes
      routes.oAuthMicrosoft,
      routes.oAuthGoogle,
      routes.signupAcceptInvitation,
      routes.signupCreateOrganizationEmailSignup,
      // SSO authentication
      routes.signupCreateOrganizationSelectSignupType,
    ].includes(path)
  ) {
    const redirect = redirectAfterLogin || routes.root;
    return <Redirect to={redirect} />;
  }

  if (userIsAuthenticated && !canBuySubscription && path === routes.checkout) {
    return <Redirect to={routes.subscription} />;
  }

  // userIsAuthenticated || !userNeedsLogin
  return <Route {...rest} render={props => <Component {...props} />} />;
};
