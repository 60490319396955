import React, { HtmlHTMLAttributes, HTMLProps } from 'react';
import classNames from 'classnames';
import { block } from './constants';

type SideNavIconProps = {
  Icon: React.ComponentType<HTMLProps<SVGElement>>;
} & HtmlHTMLAttributes<HTMLOrSVGElement>;

/**
 * Usage:
 *
 * ```tsx
 * <SideNavItem>
 *  <SideNavIcon Icon={IconAirtameLogo} />
 * </SideNavItem>
 * ```
 */
export const SideNavIcon = ({ Icon, className, ...rest }: SideNavIconProps): React.ReactElement => {
  const wrapperClassName = classNames(`${block}__item-icon`, className);

  return <Icon className={wrapperClassName} {...rest} />;
};
