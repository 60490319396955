const ns = 'signage';

export const signage = {
  page: `${ns}__page`,
  // Grid
  gridEmpty: `${ns}__grid-empty`,
  grid: `${ns}__grid`,
  gridCellName: (templateId: number): string => `${ns}__grid-cell-name-${templateId}`,
  gridCellDescription: (templateId: number): string => `${ns}__grid-cell-description-${templateId}`,
  gridCellDevicesCount: (templateId: number): string =>
    `${ns}__grid-cell-devices-count-${templateId}`,
  gridCellUpdatedAt: (templateId: number): string => `${ns}__grid-cell-updated-at-${templateId}`,
  gridCellDelete: (templateId: number): string => `${ns}__grid-cell-delete-${templateId}`,
  gridCellDuplicate: (templateId: number): string => `${ns}__grid-duplicate-name-${templateId}`,
  gridCellEdit: (templateId: number): string => `${ns}__grid-cell-edit-${templateId}`,
  gridSearch: `${ns}__grid-search`,
  gridHeaderCreateNew: `${ns}__grid-header-create-new`,
  gridCreateNewWhenEmpty: `${ns}__grid-create-new-when-empty`,
  gridDeleteSignageDialog: `${ns}__grid-delete-dialog`,
  gridDeleteSignageDialogContent: `${ns}__grid-delete-dialog-content`,
  gridDeleteSignageDialogHeaderClose: `${ns}__grid-delete-dialog-header-close`,
  gridDeleteSignageDialogFooterCancel: `${ns}__grid-delete-dialog-footer-cancel`,
  gridDeleteSignageDialogFooterDelete: `${ns}__grid-delete-dialog-footer-delete`,
  gridDuplicateSignageDialog: `${ns}__grid-duplicate-dialog`,
  gridDuplicateSignageDialogContent: `${ns}__grid-duplicate-dialog-content`,
  gridDuplicateSignageDialogInput: `${ns}__grid-duplicate-dialog-input`,
  gridDuplicateSignageDialogHeaderClose: `${ns}__grid-duplicate-dialog-header-close`,
  gridDuplicateSignageDialogFooterCancel: `${ns}__grid-duplicate-dialog-footer-cancel`,
  gridDuplicateSignageDialogFooterDuplicate: `${ns}__grid-duplicate-dialog-footer-duplicate`,
  // Device settings
  deviceSettingsGridDialog: `${ns}__device-settings-grid-dialog`,
  deviceSettingsGridDialogContent: `${ns}__device-settings-grid-dialog-content`,
  deviceSettingsGridDialogHeaderClose: `${ns}__device-settings-grid-dialog-header-close`,
  deviceSettingsGridDialogFooterCancel: `${ns}__device-settings-grid-dialog-footer-cancel`,
  deviceSettingsGridDialogFooterAdd: `${ns}__device-settings-grid-dialog-footer-add`,
  deviceSettingsContent: `${ns}__device-settings-content`,
  deviceSettingsLegacyContent: `${ns}__device-settings-legacy-content`,
  deviceSettingsConvert: `${ns}__device-settings-convert`,
  deviceSettingsAdd: `${ns}__device-settings-add`,
  deviceSettingsEdit: `${ns}__device-settings-edit`,
  deviceSettingsRemove: `${ns}__device-settings-remove`,
  deviceSettingsContentNotShownWarningMsg: `${ns}__device-settings-not-shown-warning-msg`,
  deviceSettingsContentUnavailableWarningMsg: `${ns}__device-settings-unavailable-warning-msg`,
  deviceSettingsRequireLicenseBadge: `${ns}__device-settings-requires-license-badge`,
  // Edit dialog
  editDialog: `${ns}__edit-dialog`,
  editDialogTabs: `${ns}__edit-dialog-tabs`,
  editDialogDevicesTab: `${ns}__edit-dialog-devices-tab`,
  editDialogOverviewTab: `${ns}__edit-dialog-overview-tab`,
  editDialogInfoMsg: `${ns}__edit-dialog-info-msg`,
  editDialogWarningMsg: `${ns}__edit-dialog-warning-msg`,
  editDialogDevicesEmpty: `${ns}__edit-dialog-devices-empty`,
  editDialogDevices: `${ns}__edit-dialog-devices`,
  editDialogDevicesGroupName: (groupOrder: number): string =>
    `${ns}__edit-dialog-devices-group-name-${groupOrder}`,
  editDialogDevicesDeviceName: (groupOrder: number, deviceId: number): string =>
    `${ns}__edit-dialog-devices-device-name-${groupOrder}-${deviceId}`,
  editDialogHeaderCancel: `${ns}__edit-dialog-header-cancel`,
  editDialogHeaderSave: `${ns}__edit-dialog-header-save`,
  editDialogNameInput: `${ns}__edit-dialog-name-input`,
  editDialogDescriptionInput: `${ns}__edit-dialog-description-input`,
  editDialogAddDefaultPlaylist: `${ns}__edit-dialog-add-default-playlist`,
  editDialogAddNewScheduledPlaylist: `${ns}__edit-dialog-add-new-scheduled-playlist`,
  editDialogAddApp: `${ns}__edit-dialog-add-app`,
  editDialogDeleteNthPlaylist: (nth: number): string => `${ns}__edit-dialog-delete-${nth}-playlist`,
  editDialogNthPlaylistsBadge: (nth: number): string => `${ns}__edit-dialog-${nth}-playlists-badge`,
  editDialogNthPlaylistsName: (nth: number): string => `${ns}__edit-dialog-${nth}-playlists-name`,
  editDialogNthPlaylistsOverviewToggle: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-overview-toggle`,
  editDialogNthPlaylistsScheduledSettings: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-scheduled-settings`,
  editDialogNthPlaylistsRecurrentCheckbox: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-recurrent-checkbox`,
  editDialogNthPlaylistsScheduleStartDate: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-schedule-start-date`,
  editDialogNthPlaylistsScheduleEndDate: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-schedule-end-date`,
  editDialogNthPlaylistsScheduleStartTime: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-schedule-start-time`,
  editDialogNthPlaylistsScheduleEndTime: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-schedule-end-time`,
  editDialogNthPlaylistsRecurrentStartTime: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-recurrent-start-time`,
  editDialogNthPlaylistsRecurrentEndTime: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-recurrent-end-time`,
  editDialogNthPlaylistsRecurrentDays: (nth: number): string =>
    `${ns}__edit-dialog-${nth}-playlists-recurrent-days`,
  editDialogNthPlaylistsRecurrentDaysShortDay: (nthPlaylist: number, shortDay: string): string =>
    `${ns}__edit-dialog-${nthPlaylist}-playlists-recurrent-days-${shortDay.toLowerCase()}-day`,
  editDialogNthPlaylistAppTitleByIndex: (nthPlaylist: number, appIndex: number): string =>
    `${ns}__edit-dialog-${nthPlaylist}-playlist-${appIndex}-app-title`,
  editDialogNthPlaylistAppSubtitleByIndex: (nthPlaylist: number, appIndex: number): string =>
    `${ns}__edit-dialog-${nthPlaylist}-playlist-${appIndex}-app-subtitle`,
  editDialogNthPlaylistAppDurationByIndex: (nthPlaylist: number, appIndex: number): string =>
    `${ns}__edit-dialog-${nthPlaylist}-playlist-${appIndex}-app-duration`,
  editDialogNthPlaylistConfigureAppByIndex: (nthPlaylist: number, appIndex: number): string =>
    `${ns}__edit-dialog-${nthPlaylist}-playlist-${appIndex}-app-configure-button`,
  editDialogNthPlaylistDeleteAppByIndex: (nthPlaylist: number, appIndex: number): string =>
    `${ns}__edit-dialog-${nthPlaylist}-playlist-${appIndex}-app-delete-button`,
  editDialogScheduledPlaylistContainer: (nthPlaylist: number): string =>
    `${ns}__edit-dialog-scheduled-playlist-container-${nthPlaylist}`,
  // Apps
  appDialogSelectApp: `${ns}__app-dialog-select-app`,
  appDialogSaveButton: `${ns}__app-dialog-save-button`,
  appDialogCloseButton: `${ns}__app-dialog-close-button`,
  appDialogGoogleSlidesPublicLinkInput: `${ns}__app-dialog-google-slides-public-link-input`,
  // Error dialog
  errorDialog: `${ns}__error-dialog`,
  errorDialogContent: `${ns}__error-dialog-content`,
  errorDialogHeaderClose: `${ns}__error-dialog-header-close`,
  errorDialogFooterOK: `${ns}__error-dialog-footer-ok`,
  // Playlist Preview
  playlistPreview: `${ns}__playlist-preview`,
  playlistPreviewNthItemByIndex: (playlistIndex: number): string =>
    `${ns}__playlist-preview-item-${playlistIndex}`,
  playlistPreviewItemBadge: `${ns}__playlist-preview-badge`,
  playlistPreviewItemDate: `${ns}__playlist-preview-item-date`,
  playlistPreviewItemDateDays: `${ns}__playlist-preview-item-date-days`,
  playlistPreviewItemDateStart: `${ns}__playlist-preview-item-date-start`,
  playlistPreviewItemDateEnd: `${ns}__playlist-preview-item-date-end`,
  playlistPreviewItemApps: `${ns}__playlist-preview-item-apps`,
  playlistPreviewNthItemAppByIndex: (appIndex: number): string =>
    `${ns}__playlist-preview-item-app-${appIndex}`,
  itemDisabledInfo: `${ns}__playlist-item-disabled-info-message`,
};
