import { defaultLayoutSettings as getDefaultLayoutSettings } from '@airtame/cloud-components';
import omit from 'lodash/omit';
import { timezones } from 'src/constants/deviceTimezones';
import { defaultSplashText } from 'src/constants/formLabels';
import {
  DeviceSettings,
  DeviceSettingsCustomFrontendFields,
  DeviceSettingsLayout,
} from 'src/typings/DeviceSettings';
import {
  DevicePlatformApiModel,
  FirmwareChannelApiModel,
  LayoutSettingsApiModel,
  SplashLayoutApiModel,
} from './apiModels';

export const notApplicableText = 'N/A';

export const defaultSplashTextColor = { rgb: { r: 37, g: 44, b: 63, a: 1 } };
export const defaultSplashBgColor = { rgb: { r: 255, g: 255, b: 255, a: 1 } };

export const deviceInfoKeysToOmit: (keyof DeviceSettingsCustomFrontendFields)[] = [
  'networkState',
  'platform',
  // Custom properties that were created in the FE but that
  // should never end up to the BE
  'resolutions',
  'signageContentId',
  'splashContentType',
  'splashContentImage',
  'splashContentUrl',
  'conferencingEnabled',
  'conferencingAudioInput',
  'conferencingAudioOutput',
  'conferencingVideoInput',
  'managePasswordEnabled',
  'deviceSpecs',
  'activeSubscriptionProduct',
  'licenses',
  'activeSubscriptionLicense',
  'hasLicense',
  'newLayoutEnabled',
];

export const defaultLayoutSettings: DeviceSettingsLayout = {
  ...omit(getDefaultLayoutSettings(), ['layoutVersion', 'layoutSimpleSignageType']),
  layoutSimpleSignageType: 'image',
};

const platform = DevicePlatformApiModel.DG1;
const version = 'v1.0.0';

export const hardwareDevices: DevicePlatformApiModel[] = [
  DevicePlatformApiModel.DG1,
  DevicePlatformApiModel.DG2,
  DevicePlatformApiModel.CD1,
  DevicePlatformApiModel.DG3,
];

const noLicense = {
  core: false,
  hybrid: false,
};

export const defaultDeviceSettings: DeviceSettings = {
  deviceName: '-',
  id: 0,
  ap24Channel: 0,
  ap52Channel: 0,
  ap24Enabled: false,
  ap52Enabled: false,
  homescreenOrientation: 'landscape',
  isOnline: false,
  networkState: {},
  screenshotEnabled: false,
  updateChannel: FirmwareChannelApiModel.ga,
  licenses: [noLicense],
  hasLicense: false,
  hasProductLicense: false,
  canHaveCoreLicense: false,
  canHaveHybridLicense: false,
  resolutions: [],
  airplayEnabled: false,
  googlecastEnabled: false,
  miracastEnabled: false,
  apPsk: '',
  autoUpdate: false,
  discoveryProtocol: 'mdns',
  ledEnabled: false,
  managePassword: '',
  managePasswordEnabled: false,
  pincodeEnabled: false,
  pincodeOverrideEnabled: false,
  preferredClockFormat: '',
  resolution: '',
  splashBgColor: defaultSplashBgColor,
  splashTextColor: defaultSplashTextColor,
  splashCustomText: defaultSplashText,
  splashContentType: 'image',
  splashLayout: SplashLayoutApiModel.FULL,
  timezone: timezones[0],
  wifiForwarding: false,
  monitorInfo: {
    manufacturerName: 'Unknown',
    monitorName: 'Unknown',
    serialNumber: 0,
    productCode: 0,
  },
  mac: '38:4b:76:00:00:00',
  guestStreamingEnabled: false,
  guestStreamingCustomText: '',
  conferencingEnabled: false,
  newLayoutEnabled: true,
  platform,
  version,
  deviceSpecs: [{ platform, version }],
  ...defaultLayoutSettings,
};

export const proFeaturesLayoutSettingsOnly: (keyof LayoutSettingsApiModel)[] = [
  'layoutOverlayTheme',
  'layoutOverlayBackground',
  'layoutCalendarEnabled',
  'layoutOverlayOnTopOfSignageEnabled',
  'layoutCalendarMeetingTitlesEnabled',
  'layoutCalendarRoom',
  'layoutCalendarProvider',
  'layoutClockEnabled',
  'layoutLogoType',
  'layoutLogoCustomImage',
];

/**
 * Used to map values for bulk settings.
 *
 */
export const bulkTransformers: Partial<
  Record<keyof DeviceSettings, () => Partial<DeviceSettings>>
> = {
  managePassword: () => {
    return {
      managePasswordEnabled: false,
      managePassword: '',
    };
  },
};
