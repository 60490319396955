import { subscriptionApi } from 'src/constants/api';
import { fetchJson } from 'src/utils/fetch';
import { TrialApiModel } from './apiModels';

type GetTrialApiResponse = TrialApiModel;

export async function getTrialService(): Promise<GetTrialApiResponse | null> {
  try {
    return await fetchJson(subscriptionApi.trial());
  } catch (err) {
    if (err.status === 404) {
      return null;
    }
    throw err;
  }
}
