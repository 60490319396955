import Cookies from 'js-cookie';
import * as cookies from './constants';

/**
 * Clear all cookies (especially on user logout)
 */
export function clearAllCookies(): void {
  for (const cookieName of Object.values(cookies)) {
    Cookies.remove(cookieName);
  }
}
