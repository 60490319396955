import { countriesRawList, unitedStatesRawList } from './constants';
import { Country } from './types';

export const countriesList: Country[] = countriesRawList.map(c => ({
  id: c.code,
  label: c.name,
  value: c.code,
}));

export const unitedStatesList: Country[] = unitedStatesRawList.map(c => ({
  id: c.code,
  label: c.name,
  value: c.code,
}));

export const countriesByCode = countriesList.reduce((acc: { [id: string]: Country }, country) => {
  acc[country.id] = country;
  return acc;
}, {});
