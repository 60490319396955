const ns = 'assign-license-modal';

export const assignLicenseModal = {
  proModal: `${ns}__pro-modal`,
  roomModal: `${ns}__room-modal`,
  summary: `${ns}__body-title`,
  saveButton: `${ns}__save-button`,
  getTableGroupCheckbox: (groupName: string): string => `${ns}__table-group-${groupName}-checkbox`,
  getTableDeviceCheckbox: (deviceName: string): string =>
    `${ns}__table-device-${deviceName}-checkbox`,
  confirmationModal: `${ns}__confirmation-modal`,
};
