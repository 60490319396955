import {
  ScheduleStandbyWeekDaysProps,
  ScheduleWeekDaysProps,
  ScheduleWeekDaysType,
} from 'src/typings/Device';
import { DeviceSettingName, DeviceSettingsRoutes } from 'src/typings/DeviceSettings';
import { slugify } from 'src/utils/string';

const ns = 'device-settings';

export const deviceSettings = {
  // Header
  headerTitle: `${ns}__header-title`,
  getHeaderAction: (actionName: 'save' | 'cancel'): string => `${ns}__header-action-${actionName}`,

  // Sidebar
  getNavItem: (routeName: DeviceSettingsRoutes): string => `${ns}__nav-item-${slugify(routeName)}`,
  getNavItemText: (routeName: DeviceSettingsRoutes): string =>
    `${ns}__nav-item-${slugify(routeName)}-text`,
  getNavItemNotification: (routeName: DeviceSettingsRoutes): string =>
    `${ns}__nav-item-${slugify(routeName)}-notification`,
  getNavItemErrorIco: (routeName: DeviceSettingsRoutes): string =>
    `${ns}__nav-item-${slugify(routeName)}-error-ico`,
  getNavItemLockIco: (routeName: DeviceSettingsRoutes): string =>
    `${ns}__nav-item-${slugify(routeName)}-lock-ico`,

  // Body
  getBodyTitle: (routeName: DeviceSettingsRoutes): string =>
    `${ns}__body-title-${slugify(routeName)}`,

  // Settings
  getFieldWrapper: (fieldName: DeviceSettingName): string => `${ns}__field-${fieldName}-wrapper`,
  getField: (fieldName: DeviceSettingName): string => `${ns}__field-${fieldName}`,
  getFieldLabel: (fieldName: DeviceSettingName): string => `${ns}__field-${fieldName}-label`,
  getFieldLicenseBadge: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-license-badge`,
  getFieldLabelDescription: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-label-description`,
  getFieldResetAction: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-reset-action`,
  getFieldRemoveUploadAction: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-remove-upload-action`,
  getFieldRadio: (fieldName: DeviceSettingName, radioValue: string): string =>
    `${ns}__field-${fieldName}-${radioValue}-radio`,
  getFieldRadioLabel: (fieldName: DeviceSettingName, radioValue: string): string =>
    `${ns}__field-${fieldName}-${radioValue}-radio-label`,
  getFieldDescription: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-description`,
  getFieldInfoMsg: (
    fieldName: DeviceSettingName,
    fieldType: 'info' | 'warning' | 'error'
  ): string => `${ns}__field-${fieldName}-${fieldType}-info-msg`,
  getFieldError: (fieldName: DeviceSettingName): string => `${ns}__field-${fieldName}-error`,
  getFieldBulkLabel: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-bulk-label`,
  getFieldBulkLabelText: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-bulk-label-text`,
  getFieldFirmwareNotSupportedMsg: (fieldName: DeviceSettingName): string =>
    `${ns}__field-${fieldName}-fw-not-supported`,
  downloadLogsButton: `${ns}__download-logs-button`,
  airServerLogo: `${ns}__air-server-logo`,
  roomsEchoWarningMsg: `${ns}__rooms-echo-warning-msg`,
  tryOutNewLayoutWarningMsg: `${ns}__try-out-new-layout-warning-msg`,
  newLayoutUnsupportedWarningMsg: `${ns}__new-layout-unsupported-warning-msg`,
  newLayoutWelcomeMsg: `${ns}__new-layout-welcome-msg`,

  // License
  licenseOverview: `${ns}__license-overview`,
  licenseOverviewTitle: `${ns}__license-overview-title`,
  licenseOverviewAddLicenseAction: `${ns}__license-overview-add-license-action`,
  licenseOverviewChangeLicenseAction: `${ns}__license-overview-change-license-action`,
  licenseOverviewRemoveLicenseAction: `${ns}__license-overview-remove-license-action`,
  licenseOverviewDescription: `${ns}__license-overview-description`,
  confirmDeleteLicenseModal: `${ns}__confirm-delete-license-modal`,
  confirmDeleteLicenseModalRemoveAction: `${ns}__confirm-delete-license-remove-action`,

  // Settings for Scheduled
  standbyScheduled: `${ns}__schedule-standby-scheduled`,
  getStandbyWeekdayCheckbox: (weekday: ScheduleWeekDaysType): string =>
    `${ns}__schedule-standby-scheduled-weekday-${weekday}`,
  getStandbyWeekdayHourSlider: `${ns}__schedule-standby-scheduled-weekday-hour-slider`,
  getStandbyWeekdayHour: (label: keyof ScheduleStandbyWeekDaysProps): string =>
    `${ns}__schedule-standby-scheduled-weekday-hour-${label}`,
  standbyAutomatic: `${ns}__schedule-standby-automatic`,
  standbyAutomaticTime: `${ns}__schedule-standby-automatic-time`,
  getStandbyAutomaticTimeRadio: (optionName: string): string =>
    `${ns}__schedule-standby-automatic-${optionName}`,
  getAutoUpdateWeekdayCheckbox: (weekday: ScheduleWeekDaysType): string =>
    `${ns}__schedule-update-scheduled-weekday-${weekday}`,
  getAutoUpdateWeekdayHourSlider: `${ns}__schedule-update-scheduled-weekday-hour-slider`,
  getAutoUpdateWeekdayHour: (label: keyof ScheduleWeekDaysProps): string =>
    `${ns}__schedule-update-scheduled-weekday-hour-${label}`,
  getAutoRestartWeekdayCheckbox: (weekday: ScheduleWeekDaysType): string =>
    `${ns}__schedule-restart-scheduled-weekday-${weekday}`,
  getAutoRestartWeekdayHourSlider: `${ns}__schedule-restart-scheduled-weekday-hour-slider`,
  getAutoRestartWeekdayHour: (label: keyof ScheduleWeekDaysProps): string =>
    `${ns}__schedule-restart-scheduled-weekday-hour-${label}`,

  // Preview
  preview: `${ns}__preview`,
  previewLoader: `${ns}__preview-loader`,
  previewBulkMsg: `${ns}__preview-bulk-msg`,
  previewImage: `${ns}__preview-image`,
  previewIframe: `${ns}__preview-iframe`,
  previewSplashContentPlaceholder: `${ns}__preview-splash-content-placeholder`,

  // Modals
  confirmDiscardChangesModal: `${ns}__confirm-discard-changes-modal`,
  confirmDiscardChangesModalAction: `${ns}__confirm-discard-changes-modal-action`,
  confirmAppearanceMigrationModalAction: `${ns}__confirm-appearance-migration-modal-action`,

  // Signage
  signageLiteMsg: `${ns}__signage-lite-msg`,
};
