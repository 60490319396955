import { BACKEND_URL } from '../constants/parameters';

export const backendUrl = BACKEND_URL;
export const contactSalesUrl =
  'https://airtame.com/contact-sales/?utm_source=cloudsettings&utm_medium=buttonclick&utm_campaign=upgradetoplus_cta';
export const termsAndConditionsUrl =
  'https://airtame.com/legal/#master-subscription-agreement-april-21-2022-present';
export const privacyUrl = 'https://airtame.com/legal/#privacy-policy';
// Do not remove the 'localPrivacyUrl' even if is redirected to the 'privacyUrl' because
// the google slides app needs an internal url to work
export const localPrivacyUrl = 'https://airtame.cloud/privacy';
export const wifiHelpArticleUrl =
  'https://help.airtame.com/hc/en-us/articles/5361540520093-Test-Improve-WiFi-for-Airtame';
export const cloudCoreProductUrl = 'https://airtame.com/products/airtame-cloud/';
export const cloudHybridProductUrl = 'https://airtame.com/hybrid-conferencing/';
export const appGoogleSlidesUrl =
  'https://help.airtame.com/hc/en-us/articles/5147872088477-Google-Slides';
export const appGoogleSlidesPublicUrl =
  'https://help.airtame.com/hc/en-us/articles/5339561108509-Display-presentations-as-Airtame-s-background';
export const homescreenEditOverlayGuideUrl =
  'https://help.airtame.com/hc/en-us/articles/5339352432029-Edit-overlay-guide-and-text';
export const riseVisionUrl = 'https://help.airtame.com/hc/en-us/articles/5147947081757-Rise-Vision';
export const changeAirtameResolutionUrl =
  'https://help.airtame.com/hc/en-us/articles/5263474141981-How-to-change-Airtame-resolution';
export const troubleshootingSoundsUrl =
  'https://help.airtame.com/hc/en-us/articles/5309969831837-Troubleshooting-sound';
export const customVariablesUrl =
  'https://help.airtame.com/hc/en-us/articles/5339352432029-Appearance-Customize-Airtame-overlay-guide-and-text#variables';
export const globalCalendarIntegrationUrl =
  'https://help.airtame.com/hc/en-us/articles/13196416373533-Global-Calendar-Integration-for-Cloud-Apps';
export const howToAssignLicensesUrl =
  'https://help.airtame.com/hc/en-us/articles/5312276790429-How-to-Assign-a-License';
export const howLicenseRenewalWorkUrl =
  'https://help.airtame.com/hc/en-us/articles/12640071568029-How-does-License-Renewal-Work"';
export const licenseInfoUrl = 'https://help.airtame.com/hc/en-us/articles/14782543424925';
export const licenseKeysArticleUrl = 'https://help.airtame.com/hc/en-us/articles/18875626089245';
export const googleCastArticleUrl =
  'https://help.airtame.com/hc/en-us/articles/5131229897117-How-to-Present-with-Google-Cast';
export const miracastArticleUrl =
  'https://help.airtame.com/hc/en-us/articles/5142664511517-How-to-Present-with-Miracast-over-Infrastructure';
export const airplayArticleUrl =
  'https://help.airtame.com/hc/en-us/articles/5070396455837-How-to-Present-with-AirPlay';
export const latestVaWindowsVersionDownloadUrl =
  'https://downloads-website.airtame.com/getVA?platform=win&channel=latest';
export const latestVaAndroidVersionDownloadUrl =
  'https://downloads-website.airtame.com/getVA?platform=android&channel=latest ';
