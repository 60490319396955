import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import { subscriptionApi } from 'src/constants/api';
import { fetchJson } from 'src/utils/fetch';
import {
  SubscriptionApiModel,
  SubscriptionCurrentStatusApiModel,
  SubscriptionProductIntervalApiModel,
} from './apiModels';

export async function getSubscriptionService(): Promise<SubscriptionApiModel | null> {
  try {
    const subscription = await fetchJson<SubscriptionApiModel>(subscriptionApi.subscriptions());

    return transformSubscriptionForFe(subscription);
  } catch (err) {
    if (err.status === 404) {
      return null;
    }
    throw err;
  }
}

// When the subscription turns to `past_due` state, Stripe automatically starts a new cycle
// and adds 1 year or 1 month to the expiration date respectively to the billing interval.
// For this, we need to subtract this additional cycle from the expiration date.
// Also, if a subscription is in the `past_due` state, we have a grace period
// defined in Stripe, until we consider the subscription valid.
function transformSubscriptionForFe(
  subscription: SubscriptionApiModel | null
): SubscriptionApiModel | null {
  if (!subscription) {
    return null;
  }

  if (subscription.status !== SubscriptionCurrentStatusApiModel.PastDue) {
    return subscription;
  }

  const parsedExpirationDate = new Date(Date.parse(subscription.expirationDate));
  const expirationDate =
    subscription.billingInterval === SubscriptionProductIntervalApiModel.Month
      ? subMonths(parsedExpirationDate, 1)
      : subYears(parsedExpirationDate, 1);

  return { ...subscription, expirationDate: expirationDate.toISOString() };
}
