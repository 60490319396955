import { Configuration, LogLevel } from '@azure/msal-browser';
import { captureMessage } from '@sentry/react';
import { MICROSOFT_SSO_CLIENT_ID } from 'src/constants/parameters';
import { blankRedirect } from 'src/constants/routes';
import { routeToURI } from './utils';

export const msalConfiguration: Configuration = {
  auth: {
    clientId: MICROSOFT_SSO_CLIENT_ID,
    // NOTE: RedirectUri is important and requires a page to exists in application:
    // docs -> https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirecturi-considerations
    redirectUri: routeToURI(blankRedirect),
  },
  cache: {
    storeAuthStateInCookie: true,
  },
  system: {
    // NOTE: Microsoft documentation describes how to log the msal library in these documents:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-logging-dotnet
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        // NOTE: personal and organizational data (PII) (Terminology from Microsoft)
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            captureMessage(`Microsoft auth error: ${message}`, 'error');
            return;
          case LogLevel.Warning:
            captureMessage(`Microsoft auth warning: ${message}`, 'warning');
            return;
        }
      },
    },
  },
};
