import React from 'react';
import { block } from './constants';

export const FullscreenLoader = (): React.ReactElement => {
  return (
    <div className={block}>
      <div className={`${block}__spinner`} />
    </div>
  );
};
