import { SignageAppName } from 'src/typings/Signage';

const ns = 'scheduledContent';

export const scheduledContent = {
  overviewPage: `${ns}__overview-page`,
  copySettingsFromDevice: `${ns}__copy-settings-from-device`,
  addDefaultPlaylist: `${ns}__add-default-playlist`,
  addNewScheduledPlaylist: `${ns}__add-new-scheduled-playlist`,
  editNthPlaylist: (nth: number): string => `${ns}__edit-${nth}-playlist`,
  deleteNthPlaylist: (nth: number): string => `${ns}__delete-${nth}-playlist`,
  defaultPlaylistsBadge: `${ns}__default-playlists-badge`,
  nthPlaylistsBadge: (nth: number): string => `${ns}__${nth}-playlists-badge`,
  nthPlaylistsName: (nth: number): string => `${ns}__${nth}-playlists-name`,
  nthPlaylistsOverviewToggle: (nth: number): string => `${ns}__${nth}-playlists-overview-toggle`,
  nthPlaylistsScheduledSettings: (nth: number): string =>
    `${ns}__${nth}-playlists-scheduled-settings`,
  nthPlaylistsRecurrentCheckbox: (nth: number): string =>
    `${ns}__${nth}-playlists-recurrent-checkbox`,
  nthPlaylistsScheduleStartDate: (nth: number): string =>
    `${ns}__${nth}-playlists-schedule-start-date`,
  nthPlaylistsScheduleEndDate: (nth: number): string => `${ns}__${nth}-playlists-schedule-end-date`,
  nthPlaylistsScheduleStartTime: (nth: number): string =>
    `${ns}__${nth}-playlists-schedule-start-time`,
  nthPlaylistsScheduleEndTime: (nth: number): string => `${ns}__${nth}-playlists-schedule-end-time`,
  nthPlaylistsRecurrentStartTime: (nth: number): string =>
    `${ns}__${nth}-playlists-recurrent-start-time`,
  nthPlaylistsRecurrentEndTime: (nth: number): string =>
    `${ns}__${nth}-playlists-recurrent-end-time`,
  nthPlaylistsRecurrentDays: (nth: number): string => `${ns}__${nth}-playlists-recurrent-days`,
  nthPlaylistsRecurrentDaysShortDay: (nthPlaylist: number, shortDay: string): string =>
    `${ns}__${nthPlaylist}-playlists-recurrent-days-${shortDay.toLowerCase()}-day`,
  nthPlaylistXthAppTitle: (nthPlaylist: number, xthApp: number): string =>
    `${ns}__${nthPlaylist}-playlist-${xthApp}-app-title`,
  nthPlaylistXthAppSubtitle: (nthPlaylist: number, xthApp: number): string =>
    `${ns}__${nthPlaylist}-playlist-${xthApp}-app-subtitle`,
  nthPlaylistXthAppDuration: (nthPlaylist: number, xthApp: number): string =>
    `${ns}__${nthPlaylist}-playlist-${xthApp}-app-duration`,
  addApp: `${ns}__add-app`,
  cancelPlaylistModifications: `${ns}__cancel-playlist-modifications`,
  savePlaylistModifications: `${ns}__save-playlist-modifications`,
  durationNthApp: (nth: number): string => `${ns}__duration-${nth}-app`,
  configureNthApp: (nth: number): string => `${ns}__configure-${nth}-app`,
  deleteNthApp: (nth: number): string => `${ns}__delete-${nth}-app`,
  appPickerApp: `${ns}__app-picker-app`,
  notSupportedInfoBox: `${ns}__not-supported-info-box`,
  splashContentInfoBox: `${ns}__splash-content-info-box`,
  scheduledPlaylistContainer: (nthPlaylist: number): string =>
    `${ns}__scheduled-playlist-container-${nthPlaylist}`,
  itemDisabledInfo: (appName: SignageAppName): string =>
    `${ns}__playlist-item-${appName}-disabled-info-message`,
  appManager: `${ns}__signage-app-manager`,
};
