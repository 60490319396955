import { SubscriptionVoucherModalToShow } from 'src/components/routes/OrganizationSettings/Subscription/SubscriptionVoucher/types';
import { SubscriptionProductName } from 'src/typings/Subscription';

const ns = 'subscription';

export const organizationSubscription = {
  page: `${ns}__page`,
  tab: `${ns}__tab-subscription`,
  tabContent: `${ns}__tab-content`,
  productTrial: `${ns}__product-trial`,
  productTrialTitle: `${ns}__product-trial-title`,
  productTrialContent: `${ns}__product-trial-content`,
  productStartTrialAction: `${ns}__product-trial-start-trial-action`,
  productContent: (productName: SubscriptionProductName): string =>
    `${ns}__product-${productName}-content`,
  productPlusAssignLicensesAction: `${ns}__product-plus-assign-licenses-action`,
  productPlusChangeSubscription: `${ns}__product-plus-change-subscription`,
  productPlusNoSubscription: `${ns}__product-plus-no-subscription-no-trial`,
  productEduAssignLicensesAction: `${ns}__product-edu-assign-licenses-action`,
  productEduChangeSubscription: `${ns}__product-edu-change-subscription`,
  productEduNoSubscription: `${ns}__product-edu-no-subscription-no-trial`,
  productRoomsAssignLicensesAction: `${ns}__product-rooms-assign-licenses-action`,
  productRoomsChangeSubscription: `${ns}__product-rooms-change-subscription`,
  productRoomsNoSubscription: `${ns}__product-rooms-no-subscription-no-trial`,
  cancelSubscriptionButton: `${ns}__cancel-subscription-button`,
  subscriptionStatusContent: `${ns}__subscription-status-content`,
  subscriptionStatusHeader: `${ns}__subscription-status-header`,
  subscriptionStatusHeaderDescription: `${ns}__subscription-status-header-description`,
  subscriptionStatusOverviewDetailsToggle: `${ns}__subscription-status-overview-details-toggle`,
  subscriptionStatusOverviewBillingItem: `${ns}__subscription-status-overview-credit-balance`,
  voucherKeySection: `${ns}__voucher-key-section`,
  voucherKeyInput: `${ns}__voucher-key-input`,
  voucherKeyErrorMsg: `${ns}__voucher-key-error-msg`,
  voucherKeyAction: `${ns}__voucher-key-action`,
  voucherModal: (modalName: SubscriptionVoucherModalToShow): string =>
    `${ns}__voucher-${modalName}-modal`,
  voucherModalTitle: (modalName: SubscriptionVoucherModalToShow): string =>
    `${ns}__voucher-${modalName}-modal-title`,
  voucherModalContent: (modalName: SubscriptionVoucherModalToShow): string =>
    `${ns}__voucher-${modalName}-modal-content`,
  voucherModalCloseAction: (modalName: SubscriptionVoucherModalToShow): string =>
    `${ns}__voucher-${modalName}-modal-close-action`,
  voucherModalConfirmAction: (modalName: SubscriptionVoucherModalToShow): string =>
    `${ns}__voucher-${modalName}-modal-confirm-action`,
  voucherModalCancelAction: (modalName: SubscriptionVoucherModalToShow): string =>
    `${ns}__voucher-${modalName}-modal-cancel-action`,
};
