import { useCallback, useEffect, useRef } from 'react';

/**
 * Lifecycle hook providing ability to check component's mount state.
 * Returns a function that will return true if component mounted and false otherwise.
 */
export function useMountedState(): () => boolean {
  const mountedRef = useRef<boolean>(false);
  const getMountedRef = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return getMountedRef;
}
