import { Draft } from 'immer';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ENVIRONMENT } from 'src/constants/parameters';
import { ZustandStoreOptions } from 'src/utils/zustand/types';

/**
 * Wrapper Zustand's create function with the immer and devtools middleware.
 * Returns a createAction function, which is attached to the created store.
 *
 * @param storeName
 * Name of the store. You can find the created store by this name in the
 * Redux devtools.
 *
 * @param state
 * The initial state.
 *
 * @param options
 * Additional parameters.
 *
 * @param options.devtoolsOptions
 * Additional devtools options. Store name and enabled are omitted. Store name
 * is derived from the storeName parameter of this function, and enabled is
 * driven by environment variables, and will be disabled on production.
 * @see {@link https://github.com/pmndrs/zustand#redux-devtools|Devtools middleware}
 *
 * @param options.persist
 * Define if you want the store to be persisted.
 * @see {@link https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md|Persist middleware}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createZustandStore<T>(
  storeName: string,
  state: T,
  options?: ZustandStoreOptions<T>
) {
  const devtoolsOptions = options?.devtoolsOptions || {};
  const persistOptions = options?.persistOptions;

  /**
   * Wraps the state with Persist middleware if necessary.
   * @see {@link https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md|Persist middleware}
   */
  const getStateDefinition = () => {
    if (!persistOptions) {
      return () => state;
    }

    return persist<
      T,
      [['zustand/immer', never], ['zustand/devtools', never]],
      [['zustand/persist', T]]
    >(() => state, persistOptions);
  };

  /**
   * Uses Zustand's create function, but wraps the given state with
   * the immer and devtools middlewares.
   * @see {@link https://github.com/pmndrs/zustand#redux-devtools|Devtools middleware}
   * @see {@link https://github.com/pmndrs/zustand#immer-middleware|Immer middleware}
   */
  const useStore = create(
    immer(
      devtools(getStateDefinition(), {
        name: storeName,
        enabled: ENVIRONMENT === 'dev',
        ...devtoolsOptions,
      })
    )
  );

  /**
   * A wrapper for useZustandStore.setState, enforcing named actions.
   *
   * @param actionName
   * The name of this specific action. In Redux devtools, action named is prefixed with its
   * store name, e.g. storeName/actionName
   *
   * @param nextStateOrUpdater
   * An Immer next state or an updater function, defined or returns the desired state modifications.
   *
   * @param shouldReplace
   * If true, it will bypass the default merging behavior, so the defined or returned
   * state in nextStateOrUpdater won't be merged to the existing state, but the existing
   * state will be replaced completely with that.
   */
  const createAction =
    (
      actionName: string,
      nextStateOrUpdater: Partial<T> | ((state: Draft<T>) => void) | T,
      shouldReplace = false
    ) =>
    () =>
      useStore.setState(nextStateOrUpdater, shouldReplace, `${storeName}/${actionName}`);

  return { useStore, createAction };
}
