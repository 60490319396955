import { JsonValue, Provider, ProviderStatus, ResolutionDetails } from '@openfeature/web-sdk';
import Cookies from 'js-cookie';
import { FeatureFlag } from 'src/hooks/useFeature';

// This is a Provider for OpenFeature which uses local cookies to evaluate
// whether a feature flag is enabled or not.
//
// https://openfeature.dev/docs/reference/concepts/provider
export class CookieFeatureProvider implements Provider {
  readonly metadata = { name: 'Cookie' } as const;
  status = ProviderStatus.READY;

  resolveBooleanEvaluation(flagKey: FeatureFlag): ResolutionDetails<boolean> {
    const cookieValue = Cookies.get(flagKey);

    if (!cookieValue) {
      return { value: false };
    }

    const value = !!parseInt(cookieValue, 10);

    return { value };
  }

  resolveStringEvaluation(): ResolutionDetails<string> {
    throw new Error('Not implemented');
  }

  resolveNumberEvaluation(): ResolutionDetails<number> {
    throw new Error('Not implemented');
  }

  resolveObjectEvaluation<T extends JsonValue>(): ResolutionDetails<T> {
    throw new Error('Not implemented');
  }
}
