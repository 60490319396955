import { BannerType } from 'src/components/routes/root/MainLayout/Banner/types';
import { BannerVariant } from 'src/components/ui/Banner/types';

const ns = 'banner';

export const banner = {
  element: `${ns}__element`,
  type: (bannerType: BannerType): string => `${ns}__${bannerType}`,
  variant: (variant: BannerVariant): string => `${ns}__${variant}`,
  contentIcon: `${ns}__content-icon`,
  contentBody: `${ns}__content-body`,
  closeAction: `${ns}__close-action`,
  startTrialAction: `${ns}__start-trial-action`,
  changeSubscriptionAction: `${ns}__change-subscription-action`,
};
