import * as http from 'src/constants/http';
import { logoutUser } from 'src/utils/auth';
import { APIError } from 'src/utils/error';

function getHeaderObject(customHeaders?: HeadersInit): HeadersInit {
  const defaultHeaders = {
    Accept: http.applicationJson,
    'Content-Type': http.applicationJson,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: -1,
  };
  return Object.assign({}, defaultHeaders, customHeaders);
}

export type FetchBaseOptions = RequestInit & {
  method?: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';
  body?: BodyInit;
  customHeaders?: HeadersInit;
};

export function fetchOptions({
  method = http.GET,
  body,
  customHeaders,
}: FetchBaseOptions): RequestInit {
  return Object.assign(
    {},
    {
      method,
      headers: new Headers(getHeaderObject(customHeaders)),
      credentials: http.sameOrigin,
    },
    { body }
  );
}

export async function fetchBase(
  url: string,
  options?: FetchBaseOptions,
  logoutOn401 = true
): Promise<Response> {
  const response = await fetch(url, fetchOptions(options || {}));

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
  // Successful responses (200–299)
  if (response.status >= 200 && response.status <= 299) {
    return response;
  }

  if (response.status === 401 && logoutOn401) {
    // In the case of a 401, the user needs to log in again
    logoutUser();
  }

  const responseText = await response.text();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let content: any;
  try {
    content = JSON.parse(responseText);
  } catch (err) {
    throw new APIError(response.status, '', responseText);
  }
  // All error responses from Cloud API calls are of the form {message: errorText}
  // and some of them may contain additional information, stored in content
  throw new APIError(response.status, content.message, content);
}
