import { QueryClient } from '@tanstack/react-query';

const staleTime = 1000 * 20; // 20 seconds

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime,
      // make the initial data stale
      initialDataUpdatedAt: Date.now() - staleTime,
    },
  },
});
