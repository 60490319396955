/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Main reducer. Integrates all reducers into the application
 */
import { Reducer } from 'react';
import { reducer as notifications } from 'react-notification-system-redux';
import { AnyAction, CombinedState, combineReducers } from 'redux';
import { RESET_STORE } from 'src/constants/actions';
import serviceIntegration, { ServiceIntegrationState } from './serviceIntegration';
import subscription, { SubscriptionState } from './subscription';

type ReducerState = CombinedState<RootState>;

export const appReducer = (): Reducer<CombinedState<ReducerState>, AnyAction> =>
  combineReducers<ReducerState>({
    notifications,
    serviceIntegration,
    subscription,
  });

export type RootState = {
  notifications: Record<string, unknown>;
  serviceIntegration: ServiceIntegrationState;
  subscription: SubscriptionState;
};

export type RootReducer = ReturnType<typeof appReducer>;

const resetEnhancer =
  (rootReducer: RootReducer) =>
  (state: ReducerState, action: AnyAction): ReducerState => {
    if (action.type === RESET_STORE) {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
  };

export default resetEnhancer;
