import { useGetUserData } from 'src/queries/user/useGetUserData';
import { User, UserPermission, UserRole } from '../typings/User';

/**
 * Check if the user has permission for a specific view/action.
 *
 * Note that the user role 'owner' is special and will
 * have access to everything.
 */
export function useHasUserPermission(permission: UserPermission): boolean {
  const user = useGetUserData();
  const userRole = user.role;
  const userPermissions = user?.permissions;

  return userRole === UserRole.owner || !!userPermissions?.includes(permission);
}

/**
 * Temporary function, should be removed when we refactor
 * the class components where it is used.
 */
export const userHasPermission = (
  user: Pick<User, 'role' | 'permissions'>,
  permission?: UserPermission
): boolean => {
  if (!permission) {
    return false;
  }

  return user.role === UserRole.owner || !!user.permissions?.includes(permission);
};
