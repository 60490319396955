import { QueryKey, useQuery } from '@tanstack/react-query';
import { getOrganizationSettingsError } from 'src/constants/errorMessages';
import { getOrganizationService } from 'src/services/organization/getOrganizationService';
import { UseQueryOptionsWithoutQueryKey } from 'src/typings/global';
import { Organization } from 'src/typings/Organization';
import { APIError } from 'src/utils/error';
import { getOrganizationKey } from './utils';

type UseOrganizationSettingsProps = {
  options?: UseQueryOptionsWithoutQueryKey<Organization, unknown>;
  customKey?: QueryKey;
};

export const defaultOrganization: Organization = {
  orgName: '',
  orgId: 0,
  isEducationOrg: false,
  members: [],
  invitations: [],
  orgCountryCode: 'DK',
  orgCountryName: null,
  orgOwner: null,
};

export function useGetOrganizationOverview({
  options = {},
  customKey,
}: UseOrganizationSettingsProps = {}): Organization {
  const { data } = useQuery<Organization, APIError>({
    queryKey: customKey || getOrganizationKey(),
    queryFn: () => getOrganizationService(),
    ...options,
    initialData: defaultOrganization,
    meta: {
      errorMessage: getOrganizationSettingsError,
      ...options.meta,
    },
  });

  return data;
}
