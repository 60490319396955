import { useEffect, useState } from 'react';
import { useGetUserData } from 'src/queries/user/useGetUserData';
import { ZENDESK_KEY } from '../constants/parameters';

const zendeskSettings = {
  color: { theme: `#5C647A` },
};

/**
 * Enable Zendesk 'contact us' widget
 * docs: https://developer.zendesk.com/embeddables/docs/widget/introduction/
 */
export function useZendesk(): void {
  const [isScriptAppended, setIsScriptAppended] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useGetUserData();

  const zendeskWebWidgetSettings = {
    contactForm: {
      title: { '*': 'Contact us' },
      // Pre-fill known user fields
      fields: [
        { id: 'name', prefill: { '*': user.displayName } },
        { id: 'email', prefill: { '*': user.email } },
      ],
    },
  };
  const appendScript = () => {
    const script = document.createElement('script');
    script.defer = true;
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    script.addEventListener('load', () => setIsLoaded(true));
    document.body.appendChild(script);
  };

  useEffect(() => {
    // Do not allow initialization of the script if there is no ZENDESK_KEY
    // this usually happens if we're not in production environment
    if (!ZENDESK_KEY) {
      return;
    }

    // Initialize the script only when the user is logged in
    if (!user) {
      return;
    }
    // Initialize the script only once
    if (!isScriptAppended) {
      window.zESettings = zendeskSettings;
      appendScript();
      setIsScriptAppended(true);
    }
  }, [isScriptAppended, user]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (user) {
      window.zE('webWidget', 'updateSettings', zendeskWebWidgetSettings);
      window.zE('webWidget', 'show');
    } else {
      window.zE('webWidget', 'logout');
      window.zE('webWidget', 'hide');
    }
  }, [isLoaded, user]);
}
